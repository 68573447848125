export const applicationStatusMap = {
  PASSED: "Passed",
  FAILED: "Failed",
  WAITING_FOR_EXAM: "Waiting for exam"
}

export const applictionStatusToClassName = {
  PASSED: "text-green-500",
  FAILED: "text-red-500",
  WAITING_FOR_EXAM: "text-blue-500"
}

export const { WAITING_FOR_EXAM, ...closedApplicationStatusMap } = applicationStatusMap

export {}
