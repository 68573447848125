import { Dispatch, FC, SetStateAction } from "react"
import { SidebarGroup as SidebarGroupType, SidebarLink as SidebarLinkType, SidebarSection as SidebarSectionType } from "../../types/routes"
import SidebarGroup from "./SidebarGroup"
import SidebarLabel from "./SidebarLabel"
import SidebarLink from "./SidebarLink"

type Props = {
  section: SidebarSectionType
  expanded: boolean
  setExpanded: Dispatch<SetStateAction<boolean>>
}

const SidebarSection: FC<Props> = ({
  section,
  expanded,
  setExpanded
}) => {
  return (
    <div className="sidebar__section">
      {section.label
        ? <SidebarLabel>{section.label}</SidebarLabel>
        : null
      }
      <ul className="sidebar__section-list">
        {section.items.map((item, i) => (
          (item as SidebarGroupType).id
            ?
            <SidebarGroup
              key={i}
              group={item as SidebarGroupType}
              expanded={expanded}
              setExpanded={setExpanded}
            />
            :
            <SidebarLink
              key={i}
              link={item as SidebarLinkType}
            />
        ))}
      </ul>
    </div>
  )
}

export default SidebarSection
