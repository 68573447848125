/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangeStaffUserPassword200ResponseSchema } from '../models/ChangeStaffUserPassword200ResponseSchema';
import type { ChangeStaffUserPasswordRequestSchema } from '../models/ChangeStaffUserPasswordRequestSchema';
import type { ConfirmStaffUserMFASecret200ResponseSchema } from '../models/ConfirmStaffUserMFASecret200ResponseSchema';
import type { ConfirmStaffUserMFASecretRequestSchema } from '../models/ConfirmStaffUserMFASecretRequestSchema';
import type { CreateStaffUser200ResponseSchema } from '../models/CreateStaffUser200ResponseSchema';
import type { CreateStaffUserRequestSchema } from '../models/CreateStaffUserRequestSchema';
import type { DeleteStaffUser200ResponseSchema } from '../models/DeleteStaffUser200ResponseSchema';
import type { DeleteStaffUserRequestSchema } from '../models/DeleteStaffUserRequestSchema';
import type { GenerateStaffUserMFASecret200ResponseSchema } from '../models/GenerateStaffUserMFASecret200ResponseSchema';
import type { GenerateStaffUserMFASecretRequestSchema } from '../models/GenerateStaffUserMFASecretRequestSchema';
import type { GetStaffUser200ResponseSchema } from '../models/GetStaffUser200ResponseSchema';
import type { GetStaffUserRequestSchema } from '../models/GetStaffUserRequestSchema';
import type { ListStaffUsers200ResponseSchema } from '../models/ListStaffUsers200ResponseSchema';
import type { ListStaffUsersRequestSchema } from '../models/ListStaffUsersRequestSchema';
import type { MGetStaffUsers200ResponseSchema } from '../models/MGetStaffUsers200ResponseSchema';
import type { MGetStaffUsersRequestSchema } from '../models/MGetStaffUsersRequestSchema';
import type { RefreshStaffUserSession200ResponseSchema } from '../models/RefreshStaffUserSession200ResponseSchema';
import type { RequestResetStaffUserPassword200ResponseSchema } from '../models/RequestResetStaffUserPassword200ResponseSchema';
import type { RequestResetStaffUserPasswordRequestSchema } from '../models/RequestResetStaffUserPasswordRequestSchema';
import type { ResetStaffUserPassword200ResponseSchema } from '../models/ResetStaffUserPassword200ResponseSchema';
import type { ResetStaffUserPasswordRequestSchema } from '../models/ResetStaffUserPasswordRequestSchema';
import type { StaffLogin200ResponseSchema } from '../models/StaffLogin200ResponseSchema';
import type { StaffLoginRequestSchema } from '../models/StaffLoginRequestSchema';
import type { StaffLogout200ResponseSchema } from '../models/StaffLogout200ResponseSchema';
import type { UpdateStaffUser200ResponseSchema } from '../models/UpdateStaffUser200ResponseSchema';
import type { UpdateStaffUserRequestSchema } from '../models/UpdateStaffUserRequestSchema';
import type { VerifyStaffUserMFAToken200ResponseSchema } from '../models/VerifyStaffUserMFAToken200ResponseSchema';
import type { VerifyStaffUserMFATokenRequestSchema } from '../models/VerifyStaffUserMFATokenRequestSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StaffUsersService {

  /**
   * staffLogin
   * Returns a valid session given user credentials (if user has MFA set, session will be valid only for MFA token verification)
   * @param requestBody
   * @returns StaffLogin200ResponseSchema StaffLogin200Response
   * @throws ApiError
   */
  public static staffLogin(
    requestBody?: StaffLoginRequestSchema,
  ): CancelablePromise<StaffLogin200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/staff-users/staffLogin',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * staffLogout
   * Staff logout
   * @returns StaffLogout200ResponseSchema StaffLogout200Response
   * @throws ApiError
   */
  public static staffLogout(): CancelablePromise<StaffLogout200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/staff-users/staffLogout',
    });
  }

  /**
   * refreshStaffUserSession
   * Refresh staff session
   * @returns RefreshStaffUserSession200ResponseSchema RefreshStaffUserSession200Response
   * @throws ApiError
   */
  public static refreshStaffUserSession(): CancelablePromise<RefreshStaffUserSession200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/staff-users/refreshStaffUserSession',
    });
  }

  /**
   * generateStaffUserMFASecret
   * Generate a new MFA secret
   * @param requestBody
   * @returns GenerateStaffUserMFASecret200ResponseSchema GenerateStaffUserMFASecret200Response
   * @throws ApiError
   */
  public static generateStaffUserMfaSecret(
    requestBody?: GenerateStaffUserMFASecretRequestSchema,
  ): CancelablePromise<GenerateStaffUserMFASecret200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/staff-users/generateStaffUserMFASecret',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * confirmStaffUserMFASecret
   * Confirm a pending MFA secret
   * @param requestBody
   * @returns ConfirmStaffUserMFASecret200ResponseSchema ConfirmStaffUserMFASecret200Response
   * @throws ApiError
   */
  public static confirmStaffUserMfaSecret(
    requestBody?: ConfirmStaffUserMFASecretRequestSchema,
  ): CancelablePromise<ConfirmStaffUserMFASecret200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/staff-users/confirmStaffUserMFASecret',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * verifyStaffUserMFAToken
   * Verify MFA token for users with an existing secret
   * @param requestBody
   * @returns VerifyStaffUserMFAToken200ResponseSchema VerifyStaffUserMFAToken200Response
   * @throws ApiError
   */
  public static verifyStaffUserMfaToken(
    requestBody?: VerifyStaffUserMFATokenRequestSchema,
  ): CancelablePromise<VerifyStaffUserMFAToken200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/staff-users/verifyStaffUserMFAToken',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * getStaffUser
   * Returns customer user info
   * @param requestBody
   * @returns GetStaffUser200ResponseSchema GetStaffUser200Response
   * @throws ApiError
   */
  public static getStaffUser(
    requestBody?: GetStaffUserRequestSchema,
  ): CancelablePromise<GetStaffUser200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/staff-users/getStaffUser',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * mGetStaffUsers
   * Get multiple users data
   * @param requestBody
   * @returns MGetStaffUsers200ResponseSchema MGetStaffUsers200Response
   * @throws ApiError
   */
  public static mGetStaffUsers(
    requestBody?: MGetStaffUsersRequestSchema,
  ): CancelablePromise<MGetStaffUsers200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/staff-users/mGetStaffUsers',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * listStaffUsers
   * Retrieve all staff users
   * @param requestBody
   * @returns ListStaffUsers200ResponseSchema ListStaffUsers200Response
   * @throws ApiError
   */
  public static listStaffUsers(
    requestBody?: ListStaffUsersRequestSchema,
  ): CancelablePromise<ListStaffUsers200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/staff-users/listStaffUsers',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * createStaffUser
   * Create new staff user
   * @param requestBody
   * @returns CreateStaffUser200ResponseSchema CreateStaffUser200Response
   * @throws ApiError
   */
  public static createStaffUser(
    requestBody?: CreateStaffUserRequestSchema,
  ): CancelablePromise<CreateStaffUser200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/staff-users/createStaffUser',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * deleteStaffUser
   * Delete a staff user
   * @param requestBody
   * @returns DeleteStaffUser200ResponseSchema DeleteStaffUser200Response
   * @throws ApiError
   */
  public static deleteStaffUser(
    requestBody?: DeleteStaffUserRequestSchema,
  ): CancelablePromise<DeleteStaffUser200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/staff-users/deleteStaffUser',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * updateStaffUser
   * Updates an existing staff user
   * @param requestBody
   * @returns UpdateStaffUser200ResponseSchema UpdateStaffUser200Response
   * @throws ApiError
   */
  public static updateStaffUser(
    requestBody?: UpdateStaffUserRequestSchema,
  ): CancelablePromise<UpdateStaffUser200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/staff-users/updateStaffUser',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * changeStaffUserPassword
   * Change staff user password
   * @param requestBody
   * @returns ChangeStaffUserPassword200ResponseSchema ChangeStaffUserPassword200Response
   * @throws ApiError
   */
  public static changeStaffUserPassword(
    requestBody?: ChangeStaffUserPasswordRequestSchema,
  ): CancelablePromise<ChangeStaffUserPassword200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/staff-users/changeStaffUserPassword',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * requestResetStaffUserPassword
   * Request mail to reset password
   * @param requestBody
   * @returns RequestResetStaffUserPassword200ResponseSchema RequestResetStaffUserPassword200Response
   * @throws ApiError
   */
  public static requestResetStaffUserPassword(
    requestBody?: RequestResetStaffUserPasswordRequestSchema,
  ): CancelablePromise<RequestResetStaffUserPassword200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/staff-users/requestResetStaffUserPassword',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * resetStaffUserPassword
   * Reset password of a staff user
   * @param requestBody
   * @returns ResetStaffUserPassword200ResponseSchema ResetStaffUserPassword200Response
   * @throws ApiError
   */
  public static resetStaffUserPassword(
    requestBody?: ResetStaffUserPasswordRequestSchema,
  ): CancelablePromise<ResetStaffUserPassword200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/staff-users/resetStaffUserPassword',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
