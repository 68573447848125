export const SECOND = 1000
export const MINUTE = 60 * SECOND
export const HOUR = 60 * MINUTE

export const convertMilliseconds = (milliseconds: number): string => {
  if (milliseconds < 0) {
    return "EXPIRED"
  }

  const hours = Math.floor(milliseconds / HOUR)
  const minutes = Math.floor(((milliseconds - hours * HOUR) / MINUTE))
  const seconds = Math.floor(((milliseconds - hours * HOUR - minutes * MINUTE) / SECOND))

  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`
}