import React from "react"
import { createRoot } from "react-dom/client"
import "./styles/index.css"
import App from "./App"
import { OpenAPI as PrincipalsOpenAPI } from "./sdk/principals"
import { OpenAPI as CertificationsOpenAPI } from "./sdk/certifications"

PrincipalsOpenAPI.BASE = `https://api${process.env.REACT_APP_ENV !== "production" ? ".staging" : ""}.principals.soluzionifutura.it/v1`
CertificationsOpenAPI.BASE = `https://api${process.env.REACT_APP_ENV !== "production" ? ".staging" : ""}.certifications.minosse.soluzionifutura.it/v1`

const root = createRoot(document.getElementById("root")!)

root.render(<App/>)
