import { FC, useEffect, useMemo, useState } from "react"
import { useParams, useSearchParams, useNavigate } from "react-router-dom"
import { localStorage, localStorageSetSchema } from "../helpers/storage"
import { type LocalStorageSet } from "../helpers/storage"
import { convertMilliseconds } from "../helpers/time"
import { SubmissionsService, SetsService, Certification, Set as QuestionSet, TagsService, QuestionsService, Tag as CertificationTag } from "../sdk/certifications"
import Button from "../components/Button"
import QuestionCard, { QUESTION_TYPE } from "../components/QuestionCard"
import Modal from "../components/Modal"
import useError from "../hooks/useError"
import useAuth from "../hooks/useAuth"
import Spinner from "../components/Spinner"
import { toast } from "react-toastify"
import Message from "../components/Message"
import Ajv from "ajv"
import classNames from "classnames"
import Icon from "../components/Icon"
import ReviewAnswersTable from "../components/ReviewAnswersTable"
import { produce } from "immer"
import Tag from "../components/Tag"

export type UpdateAnswer = (payload: {
  questionId: string
  answerId: string
} & ({
  multiple: false
} | {
  multiple: true
  checked: boolean
})) => void

let examInterval: NodeJS.Timeout

const SetPage: FC = () => {
  const { setId, certification } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const { handleError } = useError()
  const { user } = useAuth()
  const navigate = useNavigate()

  const [submitLoading, setSubmitLoading] = useState<boolean>(false)

  const [showSubmitModal, setShowSubmitModal] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(true)
  const [set, setSet] = useState<QuestionSet | null>(null)
  const [answers, setAnswers] = useState<Record<string, string[]>>({})
  const answeredQuestions = useMemo(() => Object.values(answers).filter(markedAnswerIds => !!markedAnswerIds.length).length, [answers])
  const [isExam, setIsExam] = useState<boolean>(false)
  const [startTimestamp, setStartTimestamp] = useState<number | null>(null)
  const [timeLeft, setTimeLeft] = useState<number>(0)
  const [flaggedQuestions, setFlaggedQuestions] = useState<string[]>([])
  const [tagSuggestions, setTagSuggestions] = useState<CertificationTag[]>([])

  const reviewAnswers = searchParams.get("reviewAnswers") === "true"
  const questionNumber = Number(searchParams.get("question") ?? 1)
  const questionIndex = questionNumber - 1
  const currentQuestion = set?.questions?.[questionIndex]

  const getCachedData = (): LocalStorageSet => {
    let cachedData = localStorage.getItem(`set.${setId}`, "safe")
    if (!cachedData) {
      cachedData = {
        startTimestamp: startTimestamp ?? 0,
        isExam,
        answers,
        flaggedQuestions: []
      }
      localStorage.setItem(`set.${setId}`, cachedData)
    }

    return cachedData
  }

  const updateAnswer: UpdateAnswer = (payload) => {
    const cachedData = getCachedData()

    setAnswers(answers => {
      const newAnswers = { ...answers }

      if (!payload.multiple) {
        newAnswers[payload.questionId] = [payload.answerId]
        cachedData.answers[payload.questionId] = [payload.answerId]
      } else if (payload.checked) {
        newAnswers[payload.questionId] = [...newAnswers[payload.questionId] || [], payload.answerId]
        cachedData.answers[payload.questionId] = [...cachedData.answers[payload.questionId] || [], payload.answerId]
      } else {
        newAnswers[payload.questionId] = newAnswers[payload.questionId].filter(answerId => payload.answerId !== answerId)
        cachedData.answers[payload.questionId] = cachedData.answers[payload.questionId].filter(answerId => payload.answerId !== answerId)
      }

      localStorage.setItem(`set.${setId}`, cachedData)
      return newAnswers
    })
  }

  useEffect(() => {
    if (!setId || !certification) {
      return
    }
    if (searchParams.get("deleteCache") === "true") {
      localStorage.removeItem(`set.${setId}`)

      const updatedParams = new URLSearchParams(searchParams)
      updatedParams.delete("deleteCache")
      setSearchParams(updatedParams, { replace: true })
    }

    if (!searchParams.get("mode")) {
      const updatedParams = new URLSearchParams(searchParams)
      updatedParams.append("mode", "training")
      setSearchParams(updatedParams)
    }

    SetsService.getSet({
      handler: {
        setId,
        certification: certification as Certification
      },
      randomizeOrder: searchParams.get("randomizeOrder") === "true"
    })
      .then(({ data }) => {
        const cachedData = localStorage.getItem(`set.${setId}`, "safe")
        const isExamInQuery = searchParams.get("mode") === "exam"

        if (
          cachedData
          && cachedData.isExam === isExamInQuery
          && new Ajv().validate(localStorageSetSchema, cachedData)
          && data.timeLimit + cachedData.startTimestamp > Date.now()
        ) {
          if (cachedData.questionsOrder) {
            data.questions!.sort((a, b) => cachedData.questionsOrder!.indexOf(a.questionId) - cachedData.questionsOrder!.indexOf(b.questionId))
          }
          setSet(data)
          setAnswers(cachedData.answers)
          setIsExam(cachedData.isExam)
          setStartTimestamp(cachedData.startTimestamp)
          setTimeLeft(data.timeLimit + cachedData.startTimestamp - Date.now())
          setFlaggedQuestions(cachedData.flaggedQuestions)
        } else {
          const answersMap = data.questions!.reduce((acc: Record<string, string[]>, { questionId }) => {
            acc[questionId] = []
            return acc
          }, {})
          const now = Date.now()

          setSet(data)
          setAnswers(answersMap)
          setIsExam(isExamInQuery)
          if (!isExamInQuery) {
            setStartTimestamp(now)
          }
          setTimeLeft(data.timeLimit)

          localStorage.setItem(`set.${setId}`, {
            startTimestamp: now,
            isExam,
            answers: answersMap,
            questionsOrder: data.questions!.map(({ questionId }) => questionId),
            flaggedQuestions: []
          })
        }
      })
      .catch(handleError)
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    if (isExam && startTimestamp) {
      examInterval = setInterval(() => {
        setTimeLeft(timeLeft => timeLeft - 1000)
      }, 1000)

      return (): void => {
        if (examInterval) {
          clearInterval(examInterval)
        }
      }
    }
  }, [startTimestamp, isExam])

  useEffect(() => {
    if (examInterval && timeLeft < 0) {
      clearInterval(examInterval)
      toast.warning("Time limit exceeded for this set. Submitting answers...")
      void submitAnswers()
    }
  }, [timeLeft])

  useEffect(() => {
    const cachedData = getCachedData()
    cachedData.flaggedQuestions = flaggedQuestions
    localStorage.setItem(`set.${setId}`, cachedData)
  }, [flaggedQuestions])

  const reloadTags  = (): Promise<void> => TagsService.listTags({})
    .then(({ data }) => setTagSuggestions(data))
    .catch(handleError)

  useEffect(() => {
    void reloadTags()
  }, [currentQuestion?.tags])

  const submitAnswers = async(): Promise<void> => {
    if (!user || !startTimestamp || !setId || !certification) {
      toast.error("Missing data in state")
      return
    }

    setSubmitLoading(true)
    try {
      const { data: { submissionId } } = await SubmissionsService.createSubmission({
        handler: {
          userId: user.userId,
          setId,
          certification: certification as Certification
        },
        isExam,
        startTimestamp,
        endTimestamp: Date.now(),
        answers: Object.entries(answers).map(([questionId, markedAnswerIds]) => ({ questionId, markedAnswerIds }))
      })
      toast.success("Submission sent successfully!")

      localStorage.removeItem(`set.${set?.setId}`)
      navigate(`/submissions/${user.userId}/${certification}/${setId}/${submissionId}`)
    } catch (err) {
      handleError(err)
    } finally {
      setSubmitLoading(false)
    }
  }

  const updateQuestionIndex = (newIndex: number): void => {
    const updatedParams = new URLSearchParams(searchParams)
    updatedParams.set("question", newIndex.toString())
    setSearchParams(updatedParams)
  }


  const goToNextQuestion = (): void => {
    const index = Number(searchParams.get("question"))
    if (set && set.questions && index && index < set.questions?.length) {
      updateQuestionIndex(index + 1)
    }
  }

  const goToPrevQuestion = (): void => {
    const index = Number(searchParams.get("question"))
    if (set && index && set.questions && index > 1) {
      updateQuestionIndex(index - 1)
    }
  }

  return <>
    {
      loading && <div className={"w-full h-full flex items-center justify-center"}>
        <Spinner/>
      </div>
    }
    {
      !loading && !set && <div className={"w-full h-full flex items-center justify-center"}>
        <Message title={"Set not found"} icon={"alert-circle"}/>
      </div>
    }
    {
      !!set?.questions && <>
        <div className="set-header">
          <div className="flex justify-center items-center">
            {isExam && startTimestamp &&
              <span className="set-header__item">
                Time Left: <Tag theme="blue" className="ml-2" outline>{convertMilliseconds(timeLeft)}</Tag>
              </span>
            }
            <span className="set-header__item">Completed: <b>{answeredQuestions}/{set.questions.length}</b></span>
            {startTimestamp && searchParams.get("question") !== null && !reviewAnswers && set.questions && flaggedQuestions &&
              <span
                className="set-header__item border-none underline hover:cursor-pointer flex items-center justify-center"
                onClick={(): void => {
                  const currentQuestionId = set.questions![questionNumber - 1].questionId
                  if (flaggedQuestions.includes(currentQuestionId)) {
                    setFlaggedQuestions(flaggedQuestions.filter((questionId) => questionId !== currentQuestionId))
                  } else {
                    setFlaggedQuestions([...flaggedQuestions, currentQuestionId])
                  }
                }}
              >
                {flaggedQuestions.includes(set.questions![Number(searchParams.get("question")) - 1].questionId) ? "Unflag" : "Flag"} Question
                <span className="text-indigo-700 ml-2">
                  <Icon size={15} name={!flaggedQuestions.includes(set.questions![Number(searchParams.get("question")) - 1].questionId) ? "flag-4" : "flag-filled"} />
                </span>
              </span>
            }
          </div>
          <span className="px-3">
            {startTimestamp &&
              <>
                {!reviewAnswers ?
                  <Button
                    onClick={(): void => {
                      const updatedParams = new URLSearchParams(searchParams)
                      updatedParams.delete("question")
                      updatedParams.set("reviewAnswers", "true")
                      setSearchParams(updatedParams)
                    }}>
                        Review Answers
                  </Button>
                  :
                  <Button
                    onClick={(): void => setShowSubmitModal(true)}
                  >
                      Submit
                  </Button>
                }
              </>
            }
          </span>
        </div>
        {
          isExam && !startTimestamp &&
            <div className={"w-full h-full flex items-center justify-center"}>
              <Message icon={"file-certificate"} title={"You're about to start a set in exam mode!"}>
                <div className="text-sm italic -mt-3 mb-2">If you leave the exam simulation page, you will lose the progress.</div>
                <div className="mb-4 text-lg">
                  Time limit: {convertMilliseconds(set.timeLimit)}
                </div>
                <Button
                  size={"lg"}
                  onClick={(): void => {
                    const cachedData = getCachedData()
                    cachedData.startTimestamp = Date.now()
                    cachedData.isExam = searchParams.get("mode") === "exam"
                    localStorage.setItem(`set.${setId}`, cachedData)
                    setStartTimestamp(cachedData.startTimestamp)
                  }}
                >
                  Start exam
                </Button>
              </Message>
            </div>
        }
        {
          reviewAnswers ?
            <div>
              <h1 className="page-title mb-10 mt-20">Review Answers</h1>
              <ReviewAnswersTable answers={answers} questions={set.questions} flaggedQuestions={flaggedQuestions} />
              <div className="w-full flex justify-end">
                <Button
                  className="my-10 uppercase"
                  onClick={(): void => setShowSubmitModal(true)}
                >
                  Submit answers
                </Button>
              </div>
            </div>
            :
            startTimestamp &&
              <>
                <h1 className="page-title mt-14">{set.name}</h1>
                {
                  (!isExam || startTimestamp) && currentQuestion ?
                    <div className={classNames("mt-4 h-full", { "pointer-events-none opacity-75": timeLeft <= 0 })}>
                      <QuestionCard
                        text={currentQuestion.text}
                        fullExplanation={currentQuestion.explanation}
                        multiple={currentQuestion.multiple}
                        questionId={currentQuestion.questionId}
                        heading={<b>{`Question ${questionNumber}`}</b>}
                        type={QUESTION_TYPE.SET}
                        showAnswersButton={!isExam}
                        answers={currentQuestion.answers}
                        markedAnswerIds={answers[currentQuestion.questionId]}
                        updateAnswer={updateAnswer}
                        goToNextQuestion={goToNextQuestion}
                        goToPrevQuestion={goToPrevQuestion}
                        nextQuestionDisabled={questionIndex === set.questions.length - 1}
                        prevQuestionDisabled={questionIndex === 0}
                        {
                          ...!isExam ? {
                            showTags: true,
                            questionTags: currentQuestion.tags ?? [],
                            tagSuggestions,
                            upsertTag: (payload): void => {
                              setSet(produce(set, state => {
                                const questionIndex = set.questions?.indexOf(currentQuestion) ?? 0
                                if (questionIndex !== -1 && state.questions) {
                                  const tagIndex = state.questions[questionIndex].tags?.findIndex(({ tagId }) => tagId === payload.tagId) ?? 0
                                  if (tagIndex !== -1) {
                                    state.questions[questionIndex].tags![tagIndex].name = payload.name
                                  } else {
                                    state.questions[questionIndex].tags?.push(payload)
                                  }

                                  QuestionsService.setQuestionTags(({
                                    handler: { setId: set.setId, questionId: currentQuestion.questionId },
                                    tagIds: state.questions[questionIndex].tags?.map(({ tagId }) => tagId) ?? []
                                  }))
                                    .catch(handleError)
                                }
                              }))
                            },
                            deleteTag: (tagIndex): void => {
                              setSet(produce(set, state => {
                                const questionIndex = set.questions?.indexOf(currentQuestion) ?? 0
                                if (questionIndex !== -1 && state.questions) {
                                  state.questions[questionIndex].tags?.splice(tagIndex, 1)

                                  QuestionsService.setQuestionTags(({
                                    handler: { setId: set.setId, questionId: currentQuestion.questionId },
                                    tagIds: state.questions[questionIndex].tags?.map(({ tagId }) => tagId) ?? []
                                  }))
                                    .catch(handleError)
                                }
                              }))
                            },
                            reloadTags
                          } : { showTags: false }
                        }
                      />
                    </div>
                    :
                    <Message className="mt-48" title="Question not found" icon="cloud-off" />
                }
              </>
        }
      </>
    }
    <Modal
      open={showSubmitModal}
      close={(): void => setShowSubmitModal(false)}
      closeButton={!submitLoading}
      autoClose={!submitLoading}
      title={"Submit answers"}
    >
      <span>Click <b>Confirm</b> to submit your answers.</span>
      <div className={"flex justify-end mt-8"}>
        <Button disabled={submitLoading} className={"mr-3"} theme={"secondary"} onClick={(): void => setShowSubmitModal(false)}>
          Cancel
        </Button>
        <Button onClick={submitAnswers} disabled={submitLoading}>
          Confirm
        </Button>
      </div>
    </Modal>
  </>
}

export default SetPage