import { FC, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { SubmissionsService, Submission as SubmissionType, Certification, SetsService } from "../sdk/certifications"
import Spinner from "../components/Spinner"
import useError from "../hooks/useError"
import { MINUTE } from "../helpers/time"
import { certificationsMap } from "../constants/certification"
import Tag from "../components/Tag"
import Message from "../components/Message"
import QuestionCard, { QUESTION_TYPE } from "../components/QuestionCard"
import { StaffUsersService } from "../sdk/principals"

const Submission: FC = () => {
  const { handleError } = useError()

  const { userId, certification, setId, submissionId } = useParams()
  const [loading, setLoading] = useState<boolean>(true)

  const [submission, setSubmission] = useState<SubmissionType>()
  const [username, setUsername] = useState<string>("")
  const [setName, setSetName] = useState<string>("")

  useEffect(() => {
    if (!userId || !certification || !setId || !submissionId) {
      return
    }

    Promise.all([
      SubmissionsService.getSubmission({ handler: { userId, certification: certification as Certification, setId, submissionId } }),
      StaffUsersService.getStaffUser({ handler: { userId } }),
      SetsService.getSet({ handler: { setId, certification: certification as Certification } })
    ])
      .then(([{ data: submission }, { data: { username } }, { data: { name } }]) => {
        setSubmission(submission)
        setUsername(username)
        setSetName(name)
      })
      .catch(handleError)
      .finally(() => setLoading(false))

  }, [])

  return <>
    {
      loading && <div className={"w-full h-full flex items-center justify-center"}>
        <Spinner/>
      </div>
    }
    {
      !loading && !submission && <div className={"w-full h-full flex items-center justify-center"}>
        <Message title={"Submission not found"} icon={"alert-circle"}/>
      </div>
    }
    {
      !!submission?.questions && <>
        <h1 className={"page-title"}>Submission details</h1>
        <div className="flex mt-6 gap-3">
          <Tag theme={submission.passed ? "green" : "red"}>Correct answers: {submission.correctAnswers}/{submission.totalQuestions}</Tag>
          <Tag><b>User:</b> {username}</Tag>
          <Tag><b>Certification:</b> {certificationsMap[certification as Certification]}</Tag>
          <Tag><b>Set: </b>{setName}</Tag>
          <Tag><b>Date: </b>{new Date(submission.startTimestamp).toLocaleDateString("it-IT")}</Tag>
          <Tag><b>Submitted after: </b>{Math.round((submission.endTimestamp - submission.startTimestamp) / MINUTE)} minutes</Tag>
        </div>
        <div className={"mt-4"}>
          {
            submission.questions.map((question, i) => {
              return <QuestionCard
                type={QUESTION_TYPE.SUBMISSION}
                heading={<b>{`Question ${i + 1}`}</b>}
                fullExplanation={question.explanation}
                text={question.text}
                multiple={question.answers.filter(({ correct }) => correct).length > 1}
                questionId={question.questionId}
                answers={question.answers}
              />
            })
          }
        </div>
      </>
    }
  </>
}

export default Submission