import { FC, ReactNode, HTMLProps, ChangeEventHandler } from "react"
import classNames from "classnames"

interface Props extends Omit<HTMLProps<HTMLInputElement>, "label" | "onChange" | "id" | "checked">{
  checked: boolean
  id?: string
  label?: ReactNode
  onChange?: ChangeEventHandler<HTMLInputElement>
  className?: string
  containerClassName?: string
  customDisabled?: boolean
}

const Checkbox: FC<Props> = ({
  label,
  checked,
  id,
  onChange,
  className,
  containerClassName,
  customDisabled = false,
  ...props
}) => {
  return (
    <div className={classNames("checkbox__container", containerClassName)}>
      <input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className={classNames("checkbox__input", className, { "cursor-not-allowed": customDisabled })}
        {...props}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  )
}

export default Checkbox