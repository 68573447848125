import { FunctionComponent } from "react"
import { ToastContainer } from "react-toastify"
import { toastDefaultProps } from "./constants/toast"
import Providers from "./context/Providers"
import Router from "./pages/Router"

const App: FunctionComponent = () => {
  return (
    <Providers>
      <Router />

      <ToastContainer {...toastDefaultProps} />
    </Providers>
  )
}

export default App
