import { useEffect } from "react"

type UseAutoCloseParams = {
  condition: boolean
  action(): void
  excludeTargets: (HTMLElement | SVGSVGElement | null)[]
  disabled?: boolean
}

const useAutoClose = ({
  action,
  condition,
  excludeTargets,
  disabled = false
}: UseAutoCloseParams): void => {
  useEffect(() => {
    if (disabled) {
      return
    }

    if (condition) {
      const onClick = (e: MouseEvent): void => {
        const target = e.target as Node
        if (!excludeTargets.some((el: HTMLElement | SVGSVGElement | null) => el?.contains(target))) {
          action()
        }
      }
      const onKeyDown = ({ key }: KeyboardEvent): void => {
        if (key === "Escape") {
          action()
        }
      }

      document.addEventListener("click", onClick)
      document.addEventListener("keydown", onKeyDown)

      return (): void => {
        document.removeEventListener("click", onClick)
        document.removeEventListener("keydown", onKeyDown)
      }
    }
  }, [condition])
}

export default useAutoClose
