import { createContext, ReactElement, ReactNode, SetStateAction, useEffect, useState } from "react"
import { localStorage } from "../helpers/storage"
import { StaffLogin200ResponseSchema, StaffUser, OpenAPI as PrincipalsOpenAPI, StaffUsersService } from "../sdk/principals"
import useError from "../hooks/useError"
import { OpenAPI as CertificationsOpenAPI } from "../sdk/certifications"
import { Buffer } from "buffer"

export enum USER_ROLES {
  AUTHENTICATED_WITH_MFA = "AUTHENTICATED_WITH_MFA",
  AUTHENTICATED_NO_MFA = "AUTHENTICATED_NO_MFA",
  AUTHENTICATED = "(AUTHENTICATED_NO_MFA || AUTHENTICATED_WITH_MFA)",
  UNAUTHENTICATED = "UNAUTHENTICATED",
  PENDING_MFA = "PENDING_MFA",
  PENDING_PASSWORD_RESET = "PENDING_PASSWORD_RESET",
  ADMIN = "ADMIN",
  SELF = "SELF",
  SELF_CUSTOMER = "SELF_CUSTOMER",
  STAFF = "STAFF",
  CUSTOMER = "CUSTOMER"
}

export type Session = {
  sessionId: string
  userId: string
  customerId?: string
  email: string
  roles: USER_ROLES[]
  creationTimestamp: number
  expirationTimestamp: number
}

export type AuthContextType = {
  onLogout(): Promise<void>
  onLogin(session: string): Promise<{ pendingMFA: boolean }>
  user: StaffUser | null
  setUser(staffUser: SetStateAction<StaffUser | null>): void
  loadingStaffUser: boolean
  decodeSession: (encodedSession: string) => Session | null
  refreshSession: (session?: string) => Promise<string | undefined>
}

export const AuthContext = createContext<AuthContextType>({} as AuthContextType)

export default function AuthProvider({ children }: { children: ReactNode }): ReactElement {
  const [user, setUser] = useState<StaffUser | null>(null)
  const [loadingStaffUser, setLoadingStaffUser] = useState<boolean>(true)

  const { handleError } = useError()

  const decodeSession = (encodedSession: string): Session | null => {
    try {
      return JSON.parse(Buffer.from(encodedSession, "base64").toString()) as Session
    } catch (error) {
      handleError(error)
    }

    return null
  }

  const onLogout = async(): Promise<void> => {
    if (user) {
      try {
        await StaffUsersService.staffLogout()
      } catch (err) {
        handleError(err)
      }
    }

    localStorage.removeItem("session")
    PrincipalsOpenAPI.TOKEN = undefined
    CertificationsOpenAPI.TOKEN = undefined
    setUser(null)
  }

  const onLogin = async(session: StaffLogin200ResponseSchema["session"]): Promise<{ pendingMFA: boolean }> => {
    PrincipalsOpenAPI.TOKEN = session
    CertificationsOpenAPI.TOKEN = session

    const decodedSession = decodeSession(session)

    if (decodedSession && Array.isArray(decodedSession.roles) && !decodedSession.roles.includes(USER_ROLES.PENDING_MFA)) {
      localStorage.setItem("session", session)
      try {
        const { data: staffUser } = await StaffUsersService.getStaffUser({ handler: { userId: decodedSession.userId } })
        setUser(staffUser)
      } catch (error) {
        handleError(error)
      }
    }

    return { pendingMFA: !!decodedSession?.roles?.includes(USER_ROLES.PENDING_MFA) }
  }

  const refreshSession = async(session?: string): Promise<string | undefined> => {
    if (session) {
      PrincipalsOpenAPI.TOKEN = session
      CertificationsOpenAPI.TOKEN = session
    }

    try {
      const { session } = await StaffUsersService.refreshStaffUserSession()
      return session
    } catch (err) {
      if (!session) {
        handleError(err)
      }
    }
  }

  useEffect(() => {
    const sessionToken = localStorage.getItem("session")
    if (sessionToken) {
      void (async(): Promise<void> => {
        try {
          PrincipalsOpenAPI.TOKEN = sessionToken
          CertificationsOpenAPI.TOKEN = sessionToken
          const newSessionToken = await refreshSession()
          if (newSessionToken) {
            await onLogin(newSessionToken)
          }

          setLoadingStaffUser(false)
        } catch (err) {
          await onLogout()
        }
      })()
    } else {
      setLoadingStaffUser(false)
    }
  }, [])

  return (
    <AuthContext.Provider value={{
      user,
      setUser,
      loadingStaffUser,
      onLogin,
      onLogout,
      decodeSession,
      refreshSession
    }}>
      {children}
    </AuthContext.Provider>
  )
}
