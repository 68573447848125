/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateSubmission200ResponseSchema } from '../models/CreateSubmission200ResponseSchema';
import type { CreateSubmissionRequestSchema } from '../models/CreateSubmissionRequestSchema';
import type { GetSubmission200ResponseSchema } from '../models/GetSubmission200ResponseSchema';
import type { GetSubmissionRequestSchema } from '../models/GetSubmissionRequestSchema';
import type { ListSubmissions200ResponseSchema } from '../models/ListSubmissions200ResponseSchema';
import type { ListSubmissionsRequestSchema } from '../models/ListSubmissionsRequestSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SubmissionsService {

  /**
   * createSubmission
   * Create submission
   * @param requestBody
   * @returns CreateSubmission200ResponseSchema CreateSubmission200Response
   * @throws ApiError
   */
  public static createSubmission(
    requestBody?: CreateSubmissionRequestSchema,
  ): CancelablePromise<CreateSubmission200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/submissions/createSubmission',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * getSubmission
   * Get submission
   * @param requestBody
   * @returns GetSubmission200ResponseSchema GetSubmission200Response
   * @throws ApiError
   */
  public static getSubmission(
    requestBody?: GetSubmissionRequestSchema,
  ): CancelablePromise<GetSubmission200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/submissions/getSubmission',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * listSubmissions
   * List submissions
   * @param requestBody
   * @returns ListSubmissions200ResponseSchema ListSubmissions200Response
   * @throws ApiError
   */
  public static listSubmissions(
    requestBody?: ListSubmissionsRequestSchema,
  ): CancelablePromise<ListSubmissions200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/submissions/listSubmissions',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
