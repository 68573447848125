/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListQuestions200ResponseSchema } from '../models/ListQuestions200ResponseSchema';
import type { ListQuestionsRequestSchema } from '../models/ListQuestionsRequestSchema';
import type { SetQuestionTags200ResponseSchema } from '../models/SetQuestionTags200ResponseSchema';
import type { SetQuestionTagsRequestSchema } from '../models/SetQuestionTagsRequestSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class QuestionsService {

  /**
   * listQuestions
   * List questions
   * @param requestBody
   * @returns ListQuestions200ResponseSchema ListQuestions200Response
   * @throws ApiError
   */
  public static listQuestions(
    requestBody?: ListQuestionsRequestSchema,
  ): CancelablePromise<ListQuestions200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/questions/listQuestions',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * setQuestionTags
   * Set question tags
   * @param requestBody
   * @returns SetQuestionTags200ResponseSchema SetQuestionTags200Response
   * @throws ApiError
   */
  public static setQuestionTags(
    requestBody?: SetQuestionTagsRequestSchema,
  ): CancelablePromise<SetQuestionTags200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/questions/setQuestionTags',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
