import classNames from "classnames"
import { FC, HTMLProps, ReactElement, ReactNode } from "react"
import ReactSelect, { components, Props as SelectProps, Theme } from "react-select"
import Icon from "./Icon"

interface Props extends Omit<SelectProps, "components" | "styles"> {
  isFilter?: boolean
  error?: ReactNode
  errorProps?: HTMLProps<HTMLDivElement>
  label?: ReactNode
  labelProps?: HTMLProps<HTMLLabelElement>
}

export type SelectOption<T> = { label: string, value: T }

const Select: FC<Props> = ({
  className,
  isSearchable = false,
  isFilter,
  error,
  errorProps,
  label,
  labelProps,
  ...props
}) => {
  return (
    <div className={className}>
      {label
        ?
        <label
          {...labelProps}
          className={classNames("input__label", labelProps?.className)}
        >{label}</label>
        : null
      }

      <ReactSelect
        className={classNames("select", !!error && "select--error")}
        classNamePrefix="select"
        controlShouldRenderValue={!isFilter}
        components={{
          IndicatorSeparator: (): ReactElement | null => null,
          DropdownIndicator: (props): ReactElement | null => (
            <components.DropdownIndicator {...props} className={classNames(props.className, error && "select__dropdown-indicator--error")}>
              <Icon name="chevron-down" />
            </components.DropdownIndicator>
          ),
          MultiValueContainer: (props): ReactElement | null => {
            return isFilter ? null : <components.MultiValueContainer {...props} />
          },
          MultiValueRemove: (props): ReactElement | null => {
            return isFilter ? null : <components.MultiValueRemove {...props} />
          },
          ClearIndicator: (props): ReactElement | null => {
            return (
              <components.ClearIndicator {...props}>
                <Icon name="x-close" />
              </components.ClearIndicator>
            )
          }
        }}
        isSearchable={isSearchable}
        menuShouldScrollIntoView={false}
        captureMenuScroll={false}
        {...props}
        theme={(theme): Theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary75: "#6366f175",
            primary50: "#6366f150",
            primary25: "#6366f125",
            primary: "#6366f1"
          }
        })}
      />

      {error
        ?
        <div
          {...errorProps}
          className={classNames("input__error", errorProps?.className)}
        >
          {error}
        </div>
        : null
      }
    </div>
  )
}

export default Select