import { FC, useRef } from "react"
import { CSSTransition as ReactCSSTransition } from "react-transition-group"
import { BaseTransitionProps } from "../../types/transition"

const CSSTransition: FC<BaseTransitionProps> = ({
  show,
  enter = "",
  enterStart = "",
  enterEnd = "",
  leave = "",
  leaveStart = "",
  leaveEnd = "",
  appear,
  unmountOnExit,
  tag = "div",
  children,
  ...rest
}) => {
  const enterClasses = enter.split(" ").filter((s: string) => s.length)
  const enterStartClasses = enterStart.split(" ").filter((s: string) => s.length)
  const enterEndClasses = enterEnd.split(" ").filter((s: string) => s.length)
  const leaveClasses = leave.split(" ").filter((s: string) => s.length)
  const leaveStartClasses = leaveStart.split(" ").filter((s: string) => s.length)
  const leaveEndClasses = leaveEnd.split(" ").filter((s: string) => s.length)
  const removeFromDom = unmountOnExit

  function addClasses(node: HTMLElement | null, classes: string[]): void {
    if (node && classes.length) {
      node.classList.add(...classes)
    }
  }

  function removeClasses(node: HTMLElement | null, classes: string[]): void {
    if (node && classes.length) {
      node.classList.remove(...classes)
    }
  }

  const nodeRef = useRef<HTMLElement>(null)
  const Component = tag

  return (
    <ReactCSSTransition
      appear={appear}
      nodeRef={nodeRef}
      unmountOnExit={removeFromDom}
      in={show}
      addEndListener={(done: () => void): void => {
        nodeRef.current?.addEventListener("transitionend", done, false)
      }}
      onEnter={(): void => {
        if (!removeFromDom && nodeRef.current) {
          nodeRef.current.style.display = ""
        }
        addClasses(nodeRef.current, [...enterClasses, ...enterStartClasses])
      }}
      onEntering={(): void => {
        removeClasses(nodeRef.current, enterStartClasses)
        addClasses(nodeRef.current, enterEndClasses)
      }}
      onEntered={(e): void => {
        if (nodeRef.current && rest.onEntered) {
          rest.onEntered(nodeRef.current, e)
        }
        removeClasses(nodeRef.current, [...enterEndClasses, ...enterClasses])
      }}
      onExit={(): void => {
        addClasses(nodeRef.current, [...leaveClasses, ...leaveStartClasses])
      }}
      onExiting={(): void => {
        removeClasses(nodeRef.current, leaveStartClasses)
        addClasses(nodeRef.current, leaveEndClasses)
      }}
      onExited={(): void => {
        removeClasses(nodeRef.current, [...leaveEndClasses, ...leaveClasses])
        if (!removeFromDom && nodeRef.current) {
          nodeRef.current.style.display = "none"
        }
      }}
    >
      <Component ref={nodeRef} {...rest} style={{ display: !removeFromDom ? "none" : null }}>{children}</Component>
    </ReactCSSTransition>
  )
}

export default CSSTransition
