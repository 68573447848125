import { CreateSubmissionRequestSchema } from "../sdk/certifications"
import { RetrievalMode, ITypedStorage, TypedStorageOptions } from "../types/storage"

class TypedStorage<T> implements ITypedStorage<T> {
  private readonly storage: Storage
  private readonly retrievalMode: RetrievalMode

  constructor({
    storage = "localStorage",
    ignoreMissingStorage = false,
    fallbackStorage = undefined,
    retrievalMode = "raw"
  }: TypedStorageOptions = {}) {
    const browserStorage = typeof window !== "undefined" && window?.[storage]
    this.storage = browserStorage || global[storage] || fallbackStorage
    this.retrievalMode = retrievalMode

    if (!this.storage && !ignoreMissingStorage) {
      throw Error("Web Storage API not found.")
    }
  }

  public get length(): number {
    return this.storage?.length
  }

  public key<U extends keyof T>(index: number): U {
    return this.storage?.key(index) as U
  }

  public getItem<U extends keyof T>(key: U, retrievalMode: RetrievalMode = this.retrievalMode): T[U] | null {
    const item = this.storage?.getItem(key.toString())

    if (item === null) {
      return item
    }

    try {
      return JSON.parse(item) as T[U]
    } catch (error) {
      switch (retrievalMode) {
        case "safe":
          return null
        case "raw":
          // eslint-disable-next-line @typescript-eslint/ban-types
          return item as unknown as T[U]
        default:
          throw error
      }
    }
  }

  public setItem<U extends keyof T>(key: U, value: T[U]): void {
    this.storage?.setItem(key.toString(), JSON.stringify(value))
  }

  public removeItem<U extends keyof T>(key: U): void {
    this.storage?.removeItem(key.toString())
  }

  public clear(): void {
    this.storage?.clear()
  }
}

export type LocalStorageSet = Pick<CreateSubmissionRequestSchema, "startTimestamp" | "isExam"> & {
  answers: Record<string, string[]>
  questionsOrder?: string[]
  flaggedQuestions: string[]
}
export type LocalStorage = {
  session: string
  [set: `set.${string}`]: LocalStorageSet
  questionsPageAnswers: Record<string, string[]>
}

export const localStorageSetSchema = {
  type: "object",
  properties: {
    answers: {
      type: "object",
      patternProperties: {
        "[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}": { type: "array", items: { type: "string" } }
      },
      additionalProperties: false
    },
    startTimestamp: { type: "number" },
    isExam: { type: "boolean" },
    questionsOrder: { type: "array", items: { type: "string" } },
    flaggedQuestions: { type: "array", items: { type: "string" } }
  },
  required: ["answers", "startTimestamp", "isExam", "flaggedQuestions"],
  additionalProperties: false
}

export const localStorage = new TypedStorage<LocalStorage>()
