/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeleteTag200ResponseSchema } from '../models/DeleteTag200ResponseSchema';
import type { DeleteTagRequestSchema } from '../models/DeleteTagRequestSchema';
import type { ListTags200ResponseSchema } from '../models/ListTags200ResponseSchema';
import type { ListTagsRequestSchema } from '../models/ListTagsRequestSchema';
import type { UpsertTag200ResponseSchema } from '../models/UpsertTag200ResponseSchema';
import type { UpsertTagRequestSchema } from '../models/UpsertTagRequestSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TagsService {

  /**
   * listTags
   * List tags
   * @param requestBody
   * @returns ListTags200ResponseSchema ListTags200Response
   * @throws ApiError
   */
  public static listTags(
    requestBody?: ListTagsRequestSchema,
  ): CancelablePromise<ListTags200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/tags/listTags',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * upsertTag
   * Upsert tag
   * @param requestBody
   * @returns UpsertTag200ResponseSchema UpsertTag200Response
   * @throws ApiError
   */
  public static upsertTag(
    requestBody?: UpsertTagRequestSchema,
  ): CancelablePromise<UpsertTag200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/tags/upsertTag',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * deleteTag
   * Delete tag
   * @param requestBody
   * @returns DeleteTag200ResponseSchema DeleteTag200Response
   * @throws ApiError
   */
  public static deleteTag(
    requestBody?: DeleteTagRequestSchema,
  ): CancelablePromise<DeleteTag200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/tags/deleteTag',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
