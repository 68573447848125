import classNames from "classnames"
import { FC, ChangeEventHandler, HTMLProps } from "react"

interface Props extends Omit<HTMLProps<HTMLInputElement>, "label" | "onChange"> {
  name: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  className?: string
  label?: string
  value: string
  containerClassName?: string
  customDisabled?: boolean
}

const RadioInput: FC<Props> = ({ name, onChange, className, label, value, checked, containerClassName, customDisabled = false, ...props }) => {
  return (
    <div className={classNames("flex items-center", containerClassName)}>
      <input
        checked={checked}
        id={value}
        type="radio"
        name={name}
        value={value}
        onChange={onChange}
        className={classNames(className, { "cursor-not-allowed": customDisabled })}
        {...props}
      />
      <label
        htmlFor={value}
        className="ml-3"
      >
        {label}
      </label>
    </div>
  )
}

export default RadioInput