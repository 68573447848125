import { useContext } from "react"
import { AuthContext, AuthContextType } from "../context/AuthContext"

type UseAuth = AuthContextType

const useAuth = (): UseAuth => {
  return useContext<AuthContextType>(AuthContext)
}

export default useAuth
