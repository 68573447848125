import classNames from "classnames"
import { FC } from "react"
import { NavLink, useLocation } from "react-router-dom"
import { sidebar } from "../../pages/wrappers/Private"
import { SidebarLink as SidebarLinkType } from "../../types/routes"
import Icon from "../Icon"
import Row from "../Row"

type Props = {
  link: SidebarLinkType
}

const SidebarLink: FC<Props> = ({
  link
}) => {
  const { pathname } = useLocation()

  const active = pathname === link.path

  return (
    <li className={classNames(
      "sidebar__item",
      active && "sidebar__item--active"
    )}>
      <NavLink
        className={classNames(
          "sidebar__item-wrapper",
          active && "sidebar__item-wrapper--active"
        )}
        to={link.path}
        onClick={(): void => {
          sidebar.current?.close()
        }}
      >
        <Row justify="between">
          <Row>
            {link.icon ?
              <Icon name={link.icon} />
              :
              <svg className="flex-shrink-0 h-6 w-6" viewBox="0 0 24 24">
                <path className={`fill-current text-gray-400 ${active && "text-indigo-300"}`} d="M13 15l11-7L11.504.136a1 1 0 00-1.019.007L0 7l13 8z" />
                <path className={`fill-current text-gray-700 ${active && "!text-indigo-600"}`} d="M13 15L0 7v9c0 .355.189.685.496.864L13 24v-9z" />
                <path className={`fill-current text-gray-600 ${active && "text-indigo-500"}`} d="M13 15.047V24l10.573-7.181A.999.999 0 0024 16V8l-11 7.047z" />
              </svg>
            }

            <span className="sidebar__item-label">{link.label}</span>
          </Row>
        </Row>
      </NavLink>
    </li>
  )
}

export default SidebarLink
