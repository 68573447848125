/* eslint-disable @typescript-eslint/no-unsafe-return */
import { FC, ComponentType, useState, useEffect, useRef, SetStateAction, Dispatch } from "react"
import { Application, ApplicationsService, Certification, ListApplicationsRequestSchema, Submission, SubmissionsService } from "../sdk/certifications"
import ApplicationsList from "../components/ApplicationsList"
import classNames from "classnames"
import { StaffUsersService } from "../sdk/principals"
import useError from "../hooks/useError"
import useAuth from "../hooks/useAuth"
import { useSearchParams } from "react-router-dom"
import Select, { SelectOption } from "../components/Select"
import Spinner from "../components/Spinner"
import Button from "../components/Button"
import Input from "../components/Input"
import { certificationFilterOptions } from "../constants/certification"
import { toast } from "react-toastify"
import CreateApplicationForm from "../components/CreateApplicationForm"
import Modal from "../components/Modal"

type SectionId = "waiting" | "closed"

export type ApplicationDataType = {
  lastSubmissions: Submission[]
  application: Application
}

export type ApplicationsSectionProps = {
  applications: ApplicationDataType[]
  changeSection(value: string): void
  users: Record<string, SelectOption<string>>
  setApplications: Dispatch<SetStateAction<ApplicationDataType[]>>
}

type Section = {
  id: SectionId
  label: string
  component: ComponentType<ApplicationsSectionProps>
}

type SectionsMap = {
  [key in SectionId]: Section
}

const sections: Section[] = [
  {
    id: "waiting",
    label: "Waiting",
    component: ApplicationsList
  },
  {
    id: "closed",
    label: "Closed",
    component: ApplicationsList
  }
]

const sectionsMap = sections.reduce((sections: SectionsMap, section: Section) => {
  sections[section.id] = section
  return sections
}, {} as SectionsMap)

type ApplicationFilters = Exclude<ListApplicationsRequestSchema["filters"], undefined>

const parseQueryFilters = (searchParams: URLSearchParams): ApplicationFilters => {
  const filters: ApplicationFilters = {}
  const entries = Array.from(searchParams.entries())
  entries.forEach(([key, value]) => {
    switch (key) {
      case "from":
        if (!filters.examTimestamp) {
          filters.examTimestamp = {}
        }
        filters.examTimestamp.from = parseInt(value)
        break
      case "to":
        if (!filters.examTimestamp) {
          filters.examTimestamp = {}
        }
        filters.examTimestamp.to = parseInt(value)
        break
      default:
        Object.assign(filters, { [key]: value })
    }
  })

  return filters
}

type UsersLastSubmissionsMap = {
  [userId: string]: {
    [certification: string]: Submission[]
  }
}

const Applications: FC = () => {
  const { handleError } = useError()
  const { user } = useAuth()
  const [searchParams, setSearchParams] = useSearchParams()

  const [activeTab, setActiveTab] = useState<SectionId>("waiting")
  const [filters, setFilters] = useState<ApplicationFilters>({})
  const [userFilterOptions, setUserFilterOptions] = useState<Record<string, SelectOption<string>>>({})
  const [loading, setLoading] = useState<boolean>(true)
  const [applications, setApplications] = useState<ApplicationDataType[]>([])
  const [sectionApplications, setSectionApplications] = useState<ApplicationDataType[]>([])
  const [createApplicationModalOpen, setCreateApplicationModalOpen] = useState<boolean>(false)

  const fromInputRef = useRef<HTMLInputElement>(null)
  const toInputRef = useRef<HTMLInputElement>(null)

  const Section = sectionsMap[activeTab || "passedOrFailed"].component

  const getApplications = async(): Promise<void> => {
    setLoading(true)
    try {
      const filters: ApplicationFilters = parseQueryFilters(searchParams)
      const { data } = await ApplicationsService.listApplications({ filters })

      const applicationUsersIds = data.map(({ userId, certification }) => ({ userId, certification }))
      const usersSubmissions = await Promise.all(applicationUsersIds.map(({ userId, certification }) => {
        return SubmissionsService.listSubmissions({ filters: { userId, certification, isExam: true } })
      }))

      const usersLastSubmissionsMap: UsersLastSubmissionsMap = applicationUsersIds.reduce((prev, current) => {
        let currentLastSubmissions: Submission[] = []
        try {
          currentLastSubmissions = usersSubmissions.find(({ data }) => data[0]?.userId === current.userId && data[0]?.certification === current.certification)?.data.sort((a, b): number => {
            const { startTimestamp: x } = a
            const { startTimestamp: y } = b

            return ((x > y) ? -1 : ((x < y) ? 1 : 0))
          }).slice(0, 3) || []
        } catch (err) {
          handleError(err)
        }
        const result: UsersLastSubmissionsMap = { ...prev }
        if (result[current.userId]) {
          result[current.userId][current.certification] = currentLastSubmissions
        } else {
          result[current.userId] = { [current.certification]: currentLastSubmissions }
        }
        return result
      }, {})

      const applicationsData: ApplicationDataType[] = data.map(application => {
        return {
          application,
          lastSubmissions: usersLastSubmissionsMap[application.userId][application.certification]
        }
      })

      setApplications(applicationsData)
    } catch (err) {
      handleError(err)
    }
    setLoading(false)
  }

  useEffect(() => {
    void (async(): Promise<void> => {
      if (filters.examTimestamp?.from && fromInputRef.current) {
        fromInputRef.current.value = new Date(filters.examTimestamp.from).toISOString().split("T")[0]
      }
      if (user?.isAdmin) {
        try {
          const { data: users } = await StaffUsersService.listStaffUsers({ active: true })
          setUserFilterOptions({
            SHOW_ALL: { label: "All users", value: "SHOW_ALL" },
            ...users.reduce((acc: Record<string, SelectOption<string>>, { userId, username }) => {
              acc[userId] = { label: username, value: userId }
              return acc
            }, {})
          })
        } catch (err) {
          handleError(err)
        }
      } else {
        const updatedParams = searchParams
        user?.userId && updatedParams.set("userId", user.userId)
        setSearchParams(updatedParams)
      }

      await getApplications()
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    const result: Record<string, string> = {}
    filters && Object.keys(filters).forEach(key => {
      if (key !== "examTimestamp") {
        result[key] = filters[key as keyof ApplicationFilters]!.toString()
      } else {
        if (filters.examTimestamp?.from) {
          result.from = filters.examTimestamp.from.toString()
        }
        if (filters.examTimestamp?.to) {
          result.to = filters.examTimestamp.to.toString()
        }
      }
    })

    setSearchParams(new URLSearchParams(result))
  }, [filters])

  useEffect(() => {
    void (async(): Promise<void> => {
      await getApplications()
    })()
  }, [searchParams])

  useEffect(() => {
    if (applications) {
      setSectionApplications(applications.filter(({ application: { status } }) => {
        if (activeTab === "closed") {
          return status !== "WAITING_FOR_EXAM"
        }
        return status === "WAITING_FOR_EXAM"
      }))
    }
  }, [activeTab, applications])

  return (
    <div className={"flex flex-col h-full"}>
      <div className="flex items-center">
        <h1 className="page-title">Applications</h1>
        <Button className="ml-auto" onClick={(): void => setCreateApplicationModalOpen(true)}>Create</Button>
      </div>

      <div className="flex items-center mt-8">
        {user?.isAdmin &&
          <>
            <Select
              className={"hover:cursor-text w-60"}
              label={"User"}
              options={Object.values(userFilterOptions)}
              isSearchable
              defaultValue={{ value: "SHOW_ALL", label: "All Users" }}
              onChange={(option): void => {
                const { value } = option as SelectOption<string>
                setFilters(filters => {
                  const newFilters = { ...filters }
                  if (value === "SHOW_ALL") {
                    delete newFilters.userId
                    delete newFilters.certification
                  } else {
                    newFilters.userId = value
                  }
                  return newFilters
                })
              }}
            />
            {
              !!filters.userId && <Select
                className={"ml-6 w-52"}
                label={"Certification"}
                value={certificationFilterOptions[filters.certification ?? "SHOW_ALL"]}
                options={Object.values(certificationFilterOptions)}
                onChange={(option): void => {
                  const { value } = option as SelectOption<ApplicationFilters>
                  setFilters(filters => {
                    const newFilters = { ...filters }
                    if (value === "SHOW_ALL") {
                      delete newFilters.certification
                    } else {
                      newFilters.certification = value as Certification
                    }

                    return newFilters
                  })
                }}
              />
            }
            <Input
              className={"ml-6 w-52"}
              type={"date"}
              label={"From"}
              inputRef={fromInputRef}
              onChange={(value): void => {
                setFilters(filters => {
                  const newFilters = { ...filters }
                  if (value) {
                    if (!newFilters.examTimestamp) {
                      newFilters.examTimestamp = {}
                    }
                    newFilters.examTimestamp.from = Date.parse(value)
                  } else {
                    delete newFilters.examTimestamp?.from
                  }

                  return newFilters
                })
              }}
            />
            <Input
              className={"ml-6 w-52"}
              type={"date"}
              label={"To"}
              inputRef={toInputRef}
              onChange={(value): void => {
                setFilters(filters => {
                  const newFilters = { ...filters }
                  if (value) {
                    if (!newFilters.examTimestamp) {
                      newFilters.examTimestamp = {}
                    }
                    newFilters.examTimestamp.to = Date.parse(value)
                  } else {
                    delete newFilters.examTimestamp?.to
                  }

                  return newFilters
                })
              }}
            />
            {
              Object.keys(filters).length > 0 && <Button
                className={"ml-auto self-end"}
                style={{ height: 38 }}
                onClick={(): void => setFilters({}) }
              >
              Clear all filters
              </Button>
            }
          </>
        }
      </div>

      <ul className="tabs__nav mt-8">
        {sections.map((section) => (
          <li
            key={section.id}
            className={classNames("tabs__item", section.id === activeTab && "tabs__item--active")}
            onClick={(): void => setActiveTab(section.id)}
          >
            {section.label}
          </li>
        ))}
      </ul>
      {loading ?
        <div className="flex w-full flex-1 justify-center mt-24">
          <Spinner />
        </div>
        :
        <div className="tabs__section">
          <Section
            applications={sectionApplications || []}
            changeSection={(section: SectionId): void => setActiveTab(section)}
            users={userFilterOptions}
            setApplications={setSectionApplications}
          />
        </div>
      }
      {createApplicationModalOpen &&
        <Modal
          title="Create Application"
          close={(): void => setCreateApplicationModalOpen(false)}
          open={createApplicationModalOpen}
        >
          <CreateApplicationForm
            userFilterOptions={userFilterOptions}
            onSubmit={async(): Promise<void> => {
              await getApplications()
              setCreateApplicationModalOpen(false)
              toast.success("Application created successfully!")
            }}
          />
        </Modal>
      }
    </div>
  )
}

export default Applications
