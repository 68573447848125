import { FC, PropsWithChildren, useRef, ReactNode, Dispatch, SetStateAction, useEffect, useState } from "react"
import { createPortal } from "react-dom"
import useAutoClose from "../hooks/useAutoClose"
import Icon from "./Icon"
import Transition from "./transition/Transition"

type Props = {
  open: boolean
  close: () => void
  title?: ReactNode
  closeButton?: boolean
  autoClose?: boolean
  footer?: ReactNode
}

const Modal: FC<PropsWithChildren<Props>> = ({
  children,
  title,
  footer,
  open,
  closeButton = true,
  autoClose = true,
  close
}) => {
  const modalRef = useRef<HTMLDivElement>(null)
  const [visible, setVisible] = useState<boolean>(false)

  useEffect(() => {
    if (!open) {
      setVisible(false)
    }
  }, [open])

  useAutoClose({
    condition: visible,
    action: close,
    excludeTargets: [modalRef.current],
    disabled: !autoClose
  })

  return createPortal(
    <>
      <Transition
        className={"modal__backdrop"}
        show={open}
        enter={"transition ease-out duration-200"}
        enterStart={"opacity-0"}
        enterEnd={"opacity-100"}
        leave={"transition ease-out duration-100"}
        leaveStart={"opacity-100"}
        leaveEnd={"opacity-0"}
        aria-hidden={"true"}
        unmountOnExit
      />
      <Transition
        className={"modal"}
        role={"dialog"}
        aria-modal={"true"}
        show={open}
        enter={"transition ease-in-out duration-200"}
        enterStart={"opacity-0 translate-y-4"}
        enterEnd={"opacity-100 translate-y-0"}
        leave={"transition ease-in-out duration-200"}
        leaveStart={"opacity-100 translate-y-0"}
        leaveEnd={"opacity-0 translate-y-4"}
        onEntered={(): void => setVisible(true) }
        unmountOnExit
      >
        <div ref={modalRef}>
          {
            !!title &&
              <div className={"modal__header"}>
                {title}
                <div className={"sr-only"}>Close</div>
                {closeButton && <Icon className={"modal__close-button"} name={"x"} onClick={close} size={20}/>}
              </div>
          }
          <div className={"modal__body"}>
            {children}
          </div>
          {
            !!footer && <div className={"modal__footer"}>
              {footer}
            </div>
          }
        </div>
      </Transition>
    </>, document.getElementById("root")!
  )
}

export default Modal
