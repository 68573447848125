import { prefixNumber } from "./format"

const getDate = (date: Date | string): Date => typeof date === "string" ? new Date(date) : date

/**
 * Formats a date into a `DD/MM/YYYY` string
 * @param {Date|string} date Date instance or ISO/UTC string
 */
export const formatDate = (date: Date | string): string => {
  const d = getDate(date)
  return `${prefixNumber(d.getDate())}/${prefixNumber(d.getMonth() + 1)}/${d.getFullYear()}`
}

export const getDateTime = (date: Date | string): string => {
  const d = getDate(date)
  return `${prefixNumber(d.getDate())}/${prefixNumber(d.getMonth() + 1)}/${prefixNumber(d.getFullYear())} - ${prefixNumber(d.getHours())}:${prefixNumber(d.getMinutes())}:${prefixNumber(d.getSeconds())}`
}

export type SplittedDate = [number, number, number]
/**
 * Returns an array [day, month, year] given a Date instance or a ISO/UTC string
 * @param {Date|string} date Date instance or ISO/UTC string
 * @param {boolean} monthIndex If true the month is returned as index. Default `false`
 */
export const getSplittedDate = (date: Date | string = new Date(), monthIndex: boolean = false): SplittedDate => {
  const d = getDate(date)
  return [
    d.getDate(),
    d.getMonth() + (monthIndex ? 0 : 1),
    d.getFullYear()
  ]
}

/**
 * Checks if the given year is a leap one.
 * @param year Year in format `YYYY`
 * @returns {boolean}
 */
export const isLeapYear = (year: number): boolean => {
  return new Date(year, 1, 29).getDate() === 29
}

type ValidateDateErrorCode = "format" | "max" | "min"
type ValidateDateOptions = {
  min?: string | Date
  max?: string | Date
  returnErrorCode?: boolean
}

/**
 * Validates a date string. if `returnErrorCode` is specified in options, a string error code is returned instead of false
 * @param {string} date Date to be validated, formatted `DD/MM/YYYY`. Leading zeros for day and month are not mandatory.
 * @param {Object} [options] - Additional validation options
 * @param {Date|string} [options.min] Minimum date allowed, can be either a Date instance or a ISO/UTC string.
 * @param {Date|string} [options.max] Maximum date allowed, can be either a Date instance or a ISO/UTC string.
 * @param {boolean} [options.returnErrorCode] If true the function returns an error code instead of a boolean
 */
export const validateDate = (date: string, options?: ValidateDateOptions): boolean | ValidateDateErrorCode => {
  const returnCode = options?.returnErrorCode
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(date)) {
    return returnCode ? "format" : false
  }

  const parts = date.split("/")
  const day = parseInt(parts[0], 10)
  const month = parseInt(parts[1], 10)
  const year = parseInt(parts[2], 10)

  const monthDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
  if (isLeapYear(year)) {
    monthDays[1] = 29
  }
  if (!day || !monthDays[month - 1] || day > monthDays[month - 1]) {
    return returnCode ? "format" : false
  }

  if (options?.min || options?.max) {
    const parsed = new Date(year, month - 1, day)
    if (options.min) {
      const minDate = typeof options.min === "string" ? new Date(options.min) : options.min
      if (parsed < minDate) {
        return returnCode ? "min" : false
      }
    }
    if (options.max) {
      const maxDate = typeof options.max === "string" ? new Date(options.max) : options.max
      if (parsed > maxDate) {
        return returnCode ? "max" : false
      }
    }
  }

  return true
}

export const validatePartialDate = (day?: string, month?: string, year?: string): boolean => {
  if (day) {
    if (day.length === 1 && +day > 3) {
      return false
    }
    if (day.length === 2 && +day > 31) {
      return false
    }
    if (month) {
      if (month.length === 1 && +month > 1) {
        return false
      }
      if (month.length === 2) {
        if (+month > 12) {
          return false
        }
        const monthDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
        if (year?.length === 4) {
          if (isLeapYear(+year)) {
            monthDays[1] = 29
          }
          if (+day > monthDays[+month - 1]) {
            return false
          }
        } else {
          monthDays[1] = 29
          if (+day > monthDays[+month - 1]) {
            return false
          }
        }
      }
    }
  }
  return true
}