import classNames from "classnames"
import { FC, HTMLProps, ReactNode } from "react"

export interface CardProps extends HTMLProps<HTMLDivElement> {
  heading?: ReactNode
  headingProps?: HTMLProps<HTMLDivElement>
}

const Card: FC<CardProps> = ({
  children,
  className,
  heading,
  headingProps,
  ...props
}) => {
  return (
    <div
      className={classNames("card", className)}
      {...props}
    >
      {heading
        ?
        <div
          {...headingProps}
          className={classNames(
            "card-heading",
            headingProps?.className
          )}
        >
          {heading}
        </div>
        : null
      }

      <div className="card-content">
        {children}
      </div>
    </div>
  )
}

export default Card
