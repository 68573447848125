import { SelectOption } from "../components/Select"
import { TagTheme } from "../components/Tag"
import { Certification } from "../sdk/certifications"

export const certificationsMap: Record<Certification, string> = {
  FOUNDATIONAL: "Cloud Practitioner",
  ASSOCIATE: "Architect Associate",
  PROFESSIONAL: "Architect Professional",
  SPECIALTY: "Specialty"
}

export type CertificationFilter = "SHOW_ALL" | Certification
export type CertificationFilterOptions = Record<string, SelectOption<CertificationFilter>>

export const certificationFilterOptions: CertificationFilterOptions = {
  SHOW_ALL: { value: "SHOW_ALL", label: "All Certifications" },
  ...Object.entries(certificationsMap).reduce((acc: CertificationFilterOptions, [value, label]) => {
    acc[value] = { value: value as Certification, label }
    return acc
  }, {})
}

export const certificationColors: {[key in Certification]: TagTheme} = {
  FOUNDATIONAL: "green",
  ASSOCIATE: "blue",
  PROFESSIONAL: "yellow",
  SPECIALTY: "purple"
}