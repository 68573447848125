import { FC, useRef, useState } from "react"
import useAuth from "../../hooks/useAuth"
import useAutoClose from "../../hooks/useAutoClose"
import Transition from "../transition/Transition"

const HeaderUser: FC = () => {
  const [open, setOpen] = useState<boolean>(false)

  const { user, onLogout } = useAuth()

  const trigger = useRef<HTMLButtonElement>(null)
  const dropdown = useRef<HTMLDivElement>(null)

  useAutoClose({
    condition: open,
    action: () => setOpen(false),
    excludeTargets: [trigger.current, dropdown.current]
  })

  if (!user) {
    return null
  }

  return (
    <div className="header__user">
      <button
        ref={trigger}
        className="header__user-toggle"
        aria-haspopup="true"
        onClick={(): void => setOpen(!open)}
        aria-expanded={open}
      >
        <div className="header__user-select">
          <span className="header__user-name">{user.username}</span>

          <svg className="header__user-icon" viewBox="0 0 12 12">
            <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
          </svg>
        </div>
      </button>

      <Transition
        className={"header__dropdown"}
        show={open}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={(): void => setOpen(true)}
          onBlur={(): void => setOpen(false)}
        >
          <div className="header__dropdown-header">
            <div className="header__dropdown-name">{user.username}</div>

            <div className="header__dropdown-role">{user.isAdmin ? "Administrator" : "Staff User" }</div>
          </div>

          <ul>
            <li>
              <span
                className="header__dropdown-item"
                onClick={onLogout}
              >
                Sign Out
              </span>
            </li>
          </ul>
        </div>
      </Transition>
    </div>
  )
}

export default HeaderUser
