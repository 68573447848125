import { FC } from "react"
import Table from "./Table"
import { Question } from "../sdk/certifications"
import { useSearchParams } from "react-router-dom"
import Icon from "./Icon"
import Col from "./Col"

type Props = {
  answers: Record<string, string[]>
  questions: Question[]
  flaggedQuestions: string[]
}

const ReviewAnswersTable: FC<Props> = ({ questions, flaggedQuestions }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <div className="w-full grid grid-cols-12 gap-x-3">
      <Col md={6}>
        <Table
          headings={["Question", "Flagged"]}
          rows={questions.slice(0, Math.round(questions.length / 2)).map(({ questionId }, index) => {
            return {
              id: index.toString(),
              children: [
                `Question ${index + 1}`,
                flaggedQuestions.includes(questionId) ? <Icon name="flag-4" size={18} /> : ""
              ],
              onRowClick: (): void => {
                const updatedParams = new URLSearchParams(searchParams)
                updatedParams.delete("reviewAnswers")
                updatedParams.set("question", (index + 1).toString())
                setSearchParams(updatedParams)
              }
            }
          }
          )}
        />
      </Col>
      <Col md={6}>
        <Table
          headings={["Question", "Flagged"]}
          rows={questions.slice(Math.round(questions.length / 2), questions.length).map(({ questionId }, index) => {
            return {
              id: index.toString(),
              children: [
                `Question ${index + 1 + Math.round(questions.length / 2)}`,
                flaggedQuestions.includes(questionId) ? <Icon name="flag-4" size={18} /> : ""
              ],
              onRowClick: (): void => {
                const updatedParams = new URLSearchParams(searchParams)
                updatedParams.delete("reviewAnswers")
                updatedParams.set("question", (index + 1 + Math.round(questions.length / 2)).toString())
                setSearchParams(updatedParams)
              }
            }
          }
          )}
        />
      </Col>
    </div>
  )
}

export default ReviewAnswersTable
