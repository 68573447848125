import { FC, ReactNode, useEffect, useState } from "react"
import { Answer, Submission, Tag } from "../sdk/certifications"
import Card from "./Card"
import Checkbox from "./Checkbox"
import RadioInput from "./RadioInput"
import Button from "./Button"
import classNames from "classnames"
import { UpdateAnswer } from "../pages/Set"
import Icon from "./Icon"
import QuestionTags from "./QuestionTags"

type SubmissionAnswer = Exclude<Submission["questions"], undefined>[0]["answers"][0]

export enum QUESTION_TYPE {
  SUBMISSION = "SUBMISSION",
  SET = "SET",
  QUESTIONS_PAGE = "QUESTIONS_PAGE"
}

type CommonCardProps = {
  text: string
  multiple: boolean
  questionId: string
  heading: ReactNode
  fullExplanation?: string
}

type SubmissionCardProps = {
  type: QUESTION_TYPE.SUBMISSION
  answers: SubmissionAnswer[]
}

type WithTags = {
  showTags: true
  questionTags: Tag[]
  tagSuggestions: Tag[]
  upsertTag: (payload: { tagId: string, name: string }) => void
  deleteTag: (index: number) => void
  reloadTags: () => Promise<void>
} | {
  showTags: false
}

type SetCardProps = WithTags & {
  type: QUESTION_TYPE.SET
  showAnswersButton: boolean
  answers: Answer[]
  updateAnswer: UpdateAnswer
  markedAnswerIds: string[]
  goToNextQuestion(): void
  goToPrevQuestion(): void
  nextQuestionDisabled: boolean
  prevQuestionDisabled: boolean
}

type QuestionsCardProps = WithTags & {
  type: QUESTION_TYPE.QUESTIONS_PAGE
  answers: Answer[]
  updateAnswer: UpdateAnswer
  markedAnswerIds: string[]
  showAnswersButton: true
}

type QuestionCardProps = CommonCardProps & (
  | SubmissionCardProps
  | SetCardProps
  | QuestionsCardProps
)

const QuestionCard: FC<QuestionCardProps> = ({
  text,
  multiple,
  questionId,
  heading,
  fullExplanation,
  ...props
}) => {
  const [showAnswers, setShowAnswers] = useState<boolean>(props.type === QUESTION_TYPE.SUBMISSION)

  useEffect(() => {
    if (props.type !== QUESTION_TYPE.SUBMISSION) {
      setShowAnswers(false)
    }
  }, [questionId])

  return <>
    <Card
      heading={
        <div className="flex items-center justify-between font-normal min-h-8">
          {heading}
          {
            props.type !== QUESTION_TYPE.SUBMISSION && props.showTags && showAnswers &&
              <QuestionTags
                tagSuggestions={props.tagSuggestions}
                questionTags={props.questionTags}
                upsertTag={props.upsertTag}
                deleteTag={props.deleteTag}
                reloadTags={props.reloadTags}
              />
          }
        </div>
      }
      className={classNames("my-3", { "h-full": props.type === QUESTION_TYPE.SET })}
    >
      <div className={"flex-1"}>
        <p className={"text-gray-800"}>{text}</p>
        {
          showAnswers && fullExplanation && <div className={"border p-3 rounded-md my-4"}>
            <div className={"text-lg font-semibold mb-3"}>Explanation</div>
            <p>{fullExplanation}</p>
          </div>
        }
        <div>
          {props.answers?.map(({ text, answerId, correct, explanation, ...answer }) => {
            const checked = props.type === QUESTION_TYPE.SUBMISSION ? (answer as SubmissionAnswer).marked : props.markedAnswerIds.includes(answerId)
            return (
              <div className={"my-2"} key={answerId}>
                {multiple ?
                  <Checkbox
                    checked={checked}
                    {...props.type !== QUESTION_TYPE.SUBMISSION && { onChange: (event): void => props.updateAnswer({ questionId, answerId, multiple: true, checked: event.target.checked }) } }
                    label={showAnswers ? explanation : text }
                    className={"mr-3"}
                    id={answerId}
                    customDisabled={showAnswers}
                    containerClassName={
                      classNames(showAnswers && {
                        "font-semibold": checked || correct,
                        "checkbox__text-green": checked && correct,
                        "checkbox__text-red": (correct && !checked) || (!correct && checked)
                      })
                    }
                  />
                  :
                  <RadioInput
                    label={showAnswers ? explanation : text}
                    value={answerId}
                    name={questionId}
                    {...props.type !== QUESTION_TYPE.SUBMISSION && { onChange: (): void => props.updateAnswer({ questionId, answerId, multiple: false }) } }
                    checked={checked}
                    customDisabled={showAnswers}
                    containerClassName={
                      classNames(showAnswers && {
                        "font-semibold": checked || correct,
                        "checkbox__text-green": checked && correct,
                        "checkbox__text-red": (correct && !checked) || (!correct && checked)
                      })
                    }
                  />
                }
              </div>
            )
          })}
        </div>
      </div>

      {
        props.type !== QUESTION_TYPE.SUBMISSION && <div className={classNames("w-full relative flex justify-center gap-3 min-h-8")}>
          {
            props.type === QUESTION_TYPE.SET && <>
              <span className={classNames("prev-next-arrow__container", props.prevQuestionDisabled && "hover:cursor-not-allowed !border-slate-200")} onClick={props.goToPrevQuestion}>
                <Icon name={"arrow-left"} className={classNames({ "stroke-slate-200": props.prevQuestionDisabled })}/>
              </span>
              <span className={classNames("prev-next-arrow__container", props.nextQuestionDisabled && "hover:cursor-not-allowed !border-slate-200")} onClick={props.goToNextQuestion}>
                <Icon name={"arrow-right"} className={classNames({ "stroke-slate-200": props.nextQuestionDisabled })}/>
              </span>
            </>
          }

          {
            props.showAnswersButton && <div className={"absolute right-0"}>
              <Button theme={"secondary"} onClick={(): void => setShowAnswers(!showAnswers)}>
                {showAnswers ? "Hide Answers" : "Show Answers"}
              </Button>
            </div>
          }
        </div>
      }
    </Card>
  </>
}

export default QuestionCard
