import { FC, useState, useEffect } from "react"
import { Set, SetsService } from "../sdk/certifications"
import SetsList from "../components/SetsList"
import useError from "../hooks/useError"
import Spinner from "../components/Spinner"
import Select, { SelectOption } from "../components/Select"
import { CertificationFilter, certificationFilterOptions } from "../constants/certification"
import Message from "../components/Message"

const SetsPage: FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [sets, setSets] = useState<Set[]>([])
  const [certification, setCertification] = useState<CertificationFilter>("SHOW_ALL")

  const { handleError } = useError()

  const loadSets = async(): Promise<void> => {
    try {
      setLoading(true)
      const { data } = await SetsService.listSets({
        ...certification !== "SHOW_ALL" && { filters: { certification } }
      })
      setSets(data)

    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    void loadSets()
  }, [certification])

  return (
    <div className={"flex flex-col h-full"}>
      <h1 className="page-title">Sets</h1>
      <div className="max-w-48 my-8">
        <Select
          className={"w-60"}
          value={certificationFilterOptions[certification]}
          options={Object.values(certificationFilterOptions)}
          label={"Certification"}
          onChange={(option): void => setCertification((option as SelectOption<CertificationFilter>).value) }
        />
      </div>
      {
        loading
          ? <div className="w-full mt-12 flex flex-1 justify-center items-center">
            <Spinner />
          </div>
          :
          sets.length > 0
            ? <SetsList sets={sets} reloadSets={loadSets}/>
            : <Message className={"mt-48"} title={"No sets found"} icon={"alert-circle"}/>
      }
    </div>
  )
}

export default SetsPage