import { FC, useRef, useState } from "react"
import useError from "../hooks/useError"
import { Tag, TagsService } from "../sdk/certifications"
import Icon from "./Icon"

type Props = {
  tag: Tag
  upsertTag: (payload: { tagId: string, name: string }) => void
  deleteTag: () => void
  reloadTags: () => Promise<void>
  allowEdit?: boolean
}

const QuestionTag: FC<Props> = ({ tag: { tagId, name }, deleteTag, upsertTag, reloadTags, allowEdit = true }) => {
  const { handleError } = useError()
  const [isEditing, setIsEditing] = useState(false)
  const oldInputValue = useRef<string>("")

  const handleTagUpdate = (newValue: string): void => {
    newValue = newValue.toUpperCase()

    if (newValue && newValue !== oldInputValue.current) {
      upsertTag({ tagId, name: newValue })

      TagsService.upsertTag({ handler: { tagId }, updates: { name: newValue } })
        .then(reloadTags)
        .catch(error => {
          handleError(error)
          upsertTag({ tagId, name: oldInputValue.current })
        })
    }
  }

  return <div className={"tag tag--primary tag--outline"}>
    {
      isEditing ? <>
        <input
          autoFocus
          className={"tagsEditor__custom-tag-input font-semibold"}
          onFocus={(event): void => {
            oldInputValue.current = event.target.value
          }}
          onBlur={(event): void => {
            handleTagUpdate(event.target.value)
            setIsEditing(false)
          }}
          defaultValue={name}
          onKeyDown={(event): void => {
            if (event.key === "Enter" && event.target instanceof HTMLInputElement) {
              handleTagUpdate(event.target.value)
              setIsEditing(false)
            }
          }}
        />
        <Icon size={16} className={"ml-1 -mr-1 stroke-indigo-500 cursor-pointer"} name={"check"}/>
      </> : <>
        <span
          onDoubleClick={(): void => {
            if (allowEdit) {
              setIsEditing(true)
            }
          }}
        >
          {name}
        </span>
        <Icon size={16} className={"ml-1 -mr-1 -mt-0.5 stroke-indigo-500 cursor-pointer"} onClick={deleteTag} name={"x-close"}/>
      </>
    }
  </div>
}

export default QuestionTag