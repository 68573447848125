import { createRef } from "react"
import { ReactElement, ReactNode } from "react"
import { Navigate, useLocation } from "react-router-dom"
import Header from "../../components/header/Header"
import Sidebar, { SidebarRef } from "../../components/sidebar/Sidebar"
import Spinner from "../../components/Spinner"
import { authRedirectPath } from "../../constants/routes"
import useAuth from "../../hooks/useAuth"

type Props = {
  children: ReactNode
  withHeader: boolean
}

export const sidebar = createRef<SidebarRef>()

export default function PrivateWrapper({ children, withHeader }: Props): ReactElement {
  const { user, loadingStaffUser } = useAuth()
  const location = useLocation()

  return (
    loadingStaffUser
      ?
      <div className="w-full h-full fixed top-0 left-0 flex justify-center items-center">
        <Spinner />
      </div>
      : user
        ?
        <div className="private">
          <Sidebar ref={sidebar} />

          <div className="private__content">
            { withHeader && <Header /> }

            <main className="private__main">
              {children}
            </main>
          </div>
        </div>
        : <Navigate to={authRedirectPath} state={{ from: location }} replace/>
  )
}
