import classNames from "classnames"
import { Dispatch, FC, SetStateAction, useState } from "react"
import { NavLink, useLocation } from "react-router-dom"
import { sidebar } from "../../pages/wrappers/Private"
import { SidebarGroup as SidebarGroupType, SidebarGroupChild } from "../../types/routes"
import Icon from "../Icon"
import Row from "../Row"

type Props = {
  group: SidebarGroupType
  expanded: boolean
  setExpanded: Dispatch<SetStateAction<boolean>>
}

const SidebarGroup: FC<Props> = ({
  group,
  expanded,
  setExpanded
}) => {
  const { pathname } = useLocation()

  const active = pathname.startsWith(`/${group.id}/`)
  const [open, setOpen] = useState(pathname.startsWith(`/${group.id}/`))

  return (
    <li className={classNames(
      "sidebar__item",
      active && "sidebar__item--active"
    )}>
      <div
        className={classNames(
          "sidebar__item-wrapper",
          active && "sidebar__item-wrapper--active"
        )}
        onClick={(): void => {
          if (expanded) {
            setOpen(!open)
          } else {
            setExpanded(true)
          }
        }}
      >
        <Row justify="between">
          <Row>
            {group.icon ?
              <Icon name={group.icon} />
              :
              <svg className="flex-shrink-0 h-6 w-6" viewBox="0 0 24 24">
                <path className={`fill-current text-gray-400 ${active && "text-indigo-300"}`} d="M13 15l11-7L11.504.136a1 1 0 00-1.019.007L0 7l13 8z" />
                <path className={`fill-current text-gray-700 ${active && "!text-indigo-600"}`} d="M13 15L0 7v9c0 .355.189.685.496.864L13 24v-9z" />
                <path className={`fill-current text-gray-600 ${active && "text-indigo-500"}`} d="M13 15.047V24l10.573-7.181A.999.999 0 0024 16V8l-11 7.047z" />
              </svg>
            }

            <span className="sidebar__item-label">{group.label}</span>
          </Row>

          <div className="sidebar__item-arrow">
            <svg
              className={classNames(
                "sidebar__item-arrow-svg",
                open && "transform rotate-180"
              )}
              viewBox="0 0 12 12"
            >
              <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
            </svg>
          </div>
        </Row>
      </div>

      <div className="sidebar__group">
        <ul className={classNames(
          "sidebar__group-list",
          !open && "hidden"
        )}>
          {group.children!.map(({ path, label }: SidebarGroupChild, i: number) => (
            <li className="sidebar__group-item" key={i}>
              <NavLink
                to={path}
                className={({ isActive }): string => classNames(
                  "sidebar__group-link",
                  isActive && "sidebar__group-link--active"
                )}
                onClick={(): void => {
                  sidebar.current?.close()
                }}
              >
                <span className="sidebar__group-item-label">{label}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </li>
  )
}

export default SidebarGroup
