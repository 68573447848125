import { FC } from "react"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import { routes } from "../constants/routes"
import AuthWrapper from "./wrappers/Auth"
import PrivateWrapper from "./wrappers/Private"
import PublicWrapper from "./wrappers/Public"

const wrappers = {
  auth: AuthWrapper,
  private: PrivateWrapper,
  public: PublicWrapper
}

const Router: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={"/"} element={<Navigate to={"/sets"} replace/>}/>
        {routes.map((route) => {
          const { path, wrapper, withHeader = true } = route
          const Wrapper = wrappers[wrapper]

          return (
            <Route
              key={path}
              path={path}
              element={
                <Wrapper withHeader={withHeader}>
                  <route.component />
                </Wrapper>
              }
            />
          )
        })}
      </Routes>
    </BrowserRouter>
  )
}

export default Router
