/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateSet200ResponseSchema } from '../models/CreateSet200ResponseSchema';
import type { CreateSetRequestSchema } from '../models/CreateSetRequestSchema';
import type { DeleteSet200ResponseSchema } from '../models/DeleteSet200ResponseSchema';
import type { DeleteSetRequestSchema } from '../models/DeleteSetRequestSchema';
import type { GetSet200ResponseSchema } from '../models/GetSet200ResponseSchema';
import type { GetSetRequestSchema } from '../models/GetSetRequestSchema';
import type { ListSets200ResponseSchema } from '../models/ListSets200ResponseSchema';
import type { ListSetsRequestSchema } from '../models/ListSetsRequestSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SetsService {

  /**
   * listSets
   * List sets
   * @param requestBody
   * @returns ListSets200ResponseSchema ListSets200Response
   * @throws ApiError
   */
  public static listSets(
    requestBody?: ListSetsRequestSchema,
  ): CancelablePromise<ListSets200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/sets/listSets',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * getSet
   * Get set
   * @param requestBody
   * @returns GetSet200ResponseSchema GetSet200Response
   * @throws ApiError
   */
  public static getSet(
    requestBody?: GetSetRequestSchema,
  ): CancelablePromise<GetSet200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/sets/getSet',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * createSet
   * Create set
   * @param requestBody
   * @returns CreateSet200ResponseSchema CreateSet200Response
   * @throws ApiError
   */
  public static createSet(
    requestBody?: CreateSetRequestSchema,
  ): CancelablePromise<CreateSet200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/sets/createSet',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * deleteSet
   * Delete set
   * @param requestBody
   * @returns DeleteSet200ResponseSchema DeleteSet200Response
   * @throws ApiError
   */
  public static deleteSet(
    requestBody?: DeleteSetRequestSchema,
  ): CancelablePromise<DeleteSet200ResponseSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/sets/deleteSet',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
