import { FC, ReactElement, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import Button from "../components/Button"
import Card from "../components/Card"
import Col from "../components/Col"
import Input from "../components/Input"
import InputPassword from "../components/InputPassword"
import Row from "../components/Row"
import Spinner from "../components/Spinner"
import useAuth from "../hooks/useAuth"
import useError from "../hooks/useError"
import { StaffLoginRequestSchema, StaffUsersService } from "../sdk/principals"
import { useNavigate, useSearchParams } from "react-router-dom"

const Login: FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const { onLogin, decodeSession, refreshSession } = useAuth()
  const { handleError } = useError()
  const { control, formState, handleSubmit } = useForm<StaffLoginRequestSchema>()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  const onSubmit = async(formData: StaffLoginRequestSchema): Promise<void> => {
    setLoading(true)
    try {
      const { session } = await StaffUsersService.staffLogin(formData)
      const { pendingMFA } = await onLogin(session)

      if (pendingMFA) {
        navigate("/mfa", { state: decodeSession(session) })
      } else {
        navigate("/")
      }
    } catch (error) {
      handleError(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    void (async(): Promise<void> => {
      const session = searchParams.get("session")
      if (session) {
        setSearchParams(new URLSearchParams())

        const newSession = await refreshSession(session)
        if (newSession) {
          await onLogin(newSession)
          navigate("/")
        }
      }
    })()
  }, [])

  return (
    <div className={"container h-full items-center grid grid-cols-12 px-4 sm:px-6 lg:px-8"}>
      <Col lg={4} className="lg:col-start-5">
        <Card>
          <h1 className="text-xl font-bold text-center">Login</h1>

          <p className="mt-1 mb-4 text-sm text-gray-400 text-center">Inserisci le credenziali per accedere alla dashboard</p>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="email"
              control={control}
              rules={{
                required: "Campo obbligatorio"
              }}
              defaultValue=""
              render={({ field: { onChange, value } }): ReactElement => (
                <Input
                  className="mb-2"
                  label="Email"
                  type="email"
                  value={value}
                  onChange={onChange}
                  error={formState.errors.email?.message}
                />
              )}
            />

            <Controller
              name="password"
              control={control}
              rules={{
                required: "Campo obbligatorio"
              }}
              defaultValue=""
              render={({ field: { onChange, value } }): ReactElement => (
                <InputPassword
                  label="Password"
                  value={value}
                  onChange={onChange}
                  error={formState.errors.password?.message}
                />
              )}
            />

            <Row className="mt-4" justify="center">
              <Button type="submit" disabled={loading}>
                {loading &&
                  <div>
                    <Spinner className="mr-3" size={20} color="gray" />
                  </div>
                }
                Login
              </Button>
            </Row>
          </form>
        </Card>
      </Col>
    </div>
  )
}

export default Login
