import classNames from "classnames"
import { FC, ReactNode } from "react"
import { IconName } from "../types/icon"
import { ColorTheme } from "../types/ui"
import Icon from "./Icon"

type Props = {
  children?: ReactNode
  className?: string
  icon?: IconName
  iconSize?: number
  theme?: ColorTheme
  title: ReactNode
}

const Message: FC<Props> = ({
  children,
  className,
  icon = "cloud-off",
  title,
  theme = "info"
}) => {
  return (
    <div className={classNames("message", className)}>
      <div className={classNames("message__icon", `message__icon--${theme}`)}>
        <Icon name={icon} size={30} />
      </div>

      <div className="message__title">{title}</div>

      {!!children &&
        <div className="message__content">{children}</div>
      }
    </div>
  )
}

export default Message
