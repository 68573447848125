import { FC, useEffect, useState, ReactElement } from "react"
import useAuth from "../hooks/useAuth"
import useError from "../hooks/useError"
import { useForm, Controller } from "react-hook-form"
import classNames from "classnames"
import Input from "../components/Input"
import Button from "../components/Button"
import Col from "../components/Col"
import Card from "../components/Card"
import { Location, useNavigate } from "react-router-dom"
import Spinner from "../components/Spinner"
import { useLocation } from "react-router-dom"
import { StaffUsersService } from "../sdk/principals"
import { toast } from "react-toastify"
import { Session } from "../context/AuthContext"

type MFAFormDataType = {
  token: string
}

const MFAPage: FC = () => {
  const { onLogin } = useAuth()
  const { handleError } = useError()
  const { control, formState, handleSubmit } = useForm<MFAFormDataType>()
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const location = useLocation() as Location & { state?: Session }

  useEffect(() => {
    if (!location.state) {
      return navigate("/login", { replace: true })
    }

    const timeout = setTimeout(() => {
      toast.warning("MFA session expired")
      navigate("/login", { replace: true })
    }, location.state.expirationTimestamp - Date.now())

    return (): void => {
      clearTimeout(timeout)
    }
  }, [])

  const onSubmit = async({ token }: MFAFormDataType): Promise<void> => {
    if (!location.state) {
      return
    }

    setLoading(true)
    try {
      const { session } = await StaffUsersService.verifyStaffUserMfaToken({ handler: { userId: location.state.userId }, token })
      await onLogin(session)
      navigate("/")
    } catch (err) {
      handleError(err)
      setLoading(false)
    }
  }

  return (
    <div className={"container h-full items-center grid grid-cols-12 px-4 sm:px-6 lg:px-8"}>
      <Col lg={4} className="lg:col-start-5">
        <Card>
          <h1 className="text-xl font-bold text-center">MFA</h1>

          <p className="mt-1 mb-4 text-sm text-gray-400 text-center">Insert MFA token</p>
          <form className={classNames(loading && "opacity-50 pointer-events-none")} onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="token"
              control={control}
              defaultValue=""
              rules={{ required: "Required" }}
              render={({ field: { value, onChange } }): ReactElement => {
                return (
                  <Input label="Token" error={formState.errors.token?.message} value={value} onChange={onChange} />
                )
              }}
            />

            <Button className="left-2/4 -translate-x-2/4 relative mt-8" type="submit" disabled={loading}>
              {loading &&
                <div>
                  <Spinner size={20} className="mr-3" color="gray" />
                </div>
              }
              Verify MFA
            </Button>
          </form>
        </Card>
      </Col>
    </div>
  )
}

export default MFAPage