import { FC, SVGProps } from "react"
import classNames from "classnames"
import SVG from "react-inlinesvg"
import { IconName } from "../types/icon"

type Props = Omit<SVGProps<SVGElement>, "onLoad" | "onError" | "ref"> & {
  name: IconName
  size?: number
}

const Icon: FC<Props> = ({ name, className, size = 20, ...props }) => {
  return <SVG
    className={classNames("icon", `icon--${name}`, className)}
    src={`${process.env.PUBLIC_URL}/icons/${name}.svg`}
    cacheRequests
    width={size}
    height={size}
    loader={
      <div className="inline-flex" style={{ width: size, height: size }} />
    }
    {...props}
  />
}

export default Icon