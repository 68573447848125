/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, ReactElement, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import Select, { SelectOption } from "./Select"
import { ApplicationsService, Certification } from "../sdk/certifications"
import Button from "./Button"
import { certificationFilterOptions } from "../constants/certification"
import classNames from "classnames"
import Input from "./Input"
import Col from "./Col"
import useError from "../hooks/useError"

type Props = {
  userFilterOptions: {[userId: string]: SelectOption<string>}
  onSubmit?(): void
}

type FormData = {
  userId: string
  certification: Certification
  examTimestamp: number
}

const CreateApplicationForm: FC<Props> = ({ userFilterOptions, onSubmit: onSubmitProp }) => {
  const { handleSubmit, control, formState } = useForm<FormData>()
  const { handleError } = useError()
  const { SHOW_ALL, ...singleCertificationFilterOptions } = certificationFilterOptions

  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit = async({ examTimestamp, ...handler }: FormData): Promise<void> => {
    setLoading(true)
    try {
      await ApplicationsService.createApplication({ handler, examTimestamp })
      if (onSubmitProp) {
        onSubmitProp()
      }
    } catch (err) {
      handleError(err)
    }
    setLoading(false)
  }

  return (
    <form className={classNames(loading && "opacity-50 pointer-events-none")} onSubmit={(handleSubmit(onSubmit))}>
      <div className="grid grid-cols-12 gap-x-3 w-full">
        <Col lg={6}>
          <Controller
            control={control}
            name="userId"
            rules={{ required: "Required" }}
            render={({ field: { onChange } }): ReactElement => {
              return (
                <Select
                  options={Object.values(userFilterOptions)}
                  label="User"
                  onChange={(option): void => onChange((option as { value: string, label: string }).value)}
                  error={formState.errors.userId?.message}
                  isSearchable={true}
                />
              )
            }}
          />
        </Col>

        <Col lg={6}>
          <Controller
            control={control}
            name="certification"
            rules={{ required: "Required" }}
            render={({ field: { onChange } }): ReactElement => {
              return (
                <Select
                  options={Object.values(singleCertificationFilterOptions)}
                  label="Certification"
                  onChange={(option): void => onChange((option as { value: string, label: string }).value)}
                  error={formState.errors.certification?.message}
                  isSearchable={true}
                />
              )
            }}
          />
        </Col>

        <Col lg={6}>
          <Controller
            rules={{ required: "Required" }}
            control={control}
            name="examTimestamp"
            render={({ field: { onChange } }): ReactElement => {
              return (
                <Input
                  className="my-3"
                  label="Exam Date"
                  type="datetime-local"
                  onChange={(value): void => onChange(Date.parse(value))}
                  error={formState.errors.examTimestamp?.message}
                />
              )
            }}
          />
        </Col>
      </div>


      <Button type="submit" disabled={loading} className="w-full mt-4">Submit</Button>
    </form>
  )
}

export default CreateApplicationForm