import { ReactElement, ReactNode } from "react"
import { Navigate } from "react-router-dom"
import Spinner from "../../components/Spinner"
import { privateRedirectPath } from "../../constants/routes"
import useAuth from "../../hooks/useAuth"

type Props = {
  children: ReactNode
}

export default function AuthWrapper({ children }: Props): ReactElement {
  const { user, loadingStaffUser } = useAuth()

  return (
    loadingStaffUser
      ?
      <div className="w-full h-full fixed top-0 left-0 flex justify-center items-center">
        <Spinner />
      </div>
      : user
        ? <Navigate to={privateRedirectPath} replace/>
        : <>{children}</>
  )
}
