import classNames from "classnames"
import { FC, useEffect, useState } from "react"
import { applicationStatusMap, applictionStatusToClassName, closedApplicationStatusMap } from "../constants/application"
import { certificationColors, certificationsMap } from "../constants/certification"
import { getDateTime } from "../helpers/date"
import { ApplicationDataType, ApplicationsSectionProps } from "../pages/Applications"
import Icon from "./Icon"
import Message from "./Message"
import Table from "./Table"
import useAuth from "../hooks/useAuth"
import { ApplicationsService, ApplicationStatus, Certification } from "../sdk/certifications"
import useError from "../hooks/useError"
import Button from "./Button"
import Modal from "./Modal"
import Spinner from "./Spinner"
import Select from "./Select"
import { useNavigate } from "react-router-dom"
import Tag from "./Tag"

type CloseApplicationOptions = {
  applicationId: string
  userId: string
  certification: Certification
  passed: boolean
}

type DeleteApplicationOptions = {
  applicationId: string
  userId: string
  certification: Certification
}

const ApplicationsList: FC<ApplicationsSectionProps> = ({ applications, users, setApplications, changeSection }) => {
  const { user } = useAuth()
  const { handleError } = useError()
  const navigate = useNavigate()

  const [activeRow, setActiveRow] = useState<string | null>()
  const [closeApplicationOptions, setCloseApplicationOptions] = useState<CloseApplicationOptions | null>(null)
  const [deleteApplicationOptions, setDeleteApplicationOptions] = useState<DeleteApplicationOptions | null>(null)
  const [applicationActionLoading, setApplicationActionLoading] = useState<boolean>(false)

  const applicationsTableHeadings = user?.isAdmin ?
    ["Candidate", "Ceritification", "Exam timestamp", "Application Status", "Last 3 Submissions", ""]
    :
    ["Ceritification", "Exam timestamp", "Application Status", "Last 3 Submissions", ""]

  const closeApplication = async(): Promise<void> => {
    if (closeApplicationOptions) {
      try {
        const { passed, ...handler } = closeApplicationOptions
        await ApplicationsService.closeApplication({ handler, passed })
      } catch (error) {
        handleError(error)
      }
    }
  }

  const deleteApplication = async(): Promise<void> => {
    if (deleteApplicationOptions) {
      try {
        const { ...handler } = deleteApplicationOptions
        await ApplicationsService.deleteApplication({ handler })
      } catch (error) {
        handleError(error)
      }
    }
  }

  return (
    applications.length > 0 ?
      <>
        <Table
          tableClassName="applications__table"
          headings={applicationsTableHeadings}
          fixedColumns={[""]}
          rows={applications.map(({ application: { applicationId, userId, certification, examTimestamp, status }, lastSubmissions }) => ({
            id: applicationId,
            children: [
              user?.isAdmin && users[userId].label,
              <Tag outline theme={certificationColors[certification]}>{certificationsMap[certification]}</Tag>,
              getDateTime(new Date(examTimestamp)),
              <span className={classNames("font-semibold", applictionStatusToClassName[status])}>{applicationStatusMap[status]}</span>,
              lastSubmissions.length > 0 ?
                lastSubmissions.map(({ correctAnswersPercentage, endTimestamp, userId, setId, submissionId, certification }) => {
                  return (
                    <div
                      className="applications__last-submission-container"
                      onClick={(): void => navigate(`/submissions/${userId}/${certification}/${setId}/${submissionId}`)}
                    >
                      <span>{correctAnswersPercentage}%</span> -
                      <span> {new Date(Date.now()).getDate() - new Date(endTimestamp).getDate()} days ago</span>
                    </div>
                  )
                })
                : "No Submissions found",
              <span className="min-w-48">
                <span className={classNames((activeRow !== applicationId) && "hidden")}>
                  <Icon
                    size={25}
                    name="edit"
                    className="applications__row-action-icon mr-3"
                    onClick={(): void =>  setCloseApplicationOptions({ applicationId, userId, certification, passed: status === "PASSED" ? false : true })}
                  />
                  <Icon
                    onClick={(): void => setDeleteApplicationOptions({ applicationId, userId, certification })}
                    size={25}
                    name="trash"
                    className="applications__row-action-icon"
                  />
                </span>
              </span>
            ],
            onRowHover: (): void => {
              setActiveRow(applicationId)
            },
            onRowOut: (): void => {
              setActiveRow(null)
            }
          }))}
        />
        {closeApplicationOptions &&
          <Modal
            close={(): void => setCloseApplicationOptions(null)}
            open={closeApplicationOptions !== null}
            title="Close Application"
          >
            <div className="mb-4">
              <Select
                options={Object.entries(closedApplicationStatusMap).map(([value, label]) => ({ value, label }))}
                label={"Status"}
                defaultValue={closeApplicationOptions.passed ? { value: "PASSED", label: "Passed" } : { value: "FAILED", label: "Failed" }}
                onChange={(): void => {
                  setCloseApplicationOptions(options => {
                    const updated = { ...options }
                    updated.passed = !options!.passed
                    return updated as CloseApplicationOptions
                  })
                }}
              />
            </div>
            <Button
              className="w-full mt-4"
              onClick={async(): Promise<void> => {
                setApplicationActionLoading(true)
                await closeApplication()
                setApplications(applications => {
                  const updated = applications
                  const target = applications.find(({ application: { applicationId } }) => applicationId === closeApplicationOptions.applicationId)
                  if (target) {
                    const status: ApplicationStatus = closeApplicationOptions.passed ? "PASSED" : "FAILED"
                    const index = applications.indexOf(target)
                    target.application.status = status
                    updated.splice(index, 1)
                    updated.splice(index, 0, target)
                  }
                  return updated as ApplicationDataType[]
                })
                setApplicationActionLoading(false)
                setCloseApplicationOptions(null)
                changeSection("closed")
              }}
              disabled={applicationActionLoading}
            >
              {applicationActionLoading &&
                <Spinner size={20} className="mr-3" />
              }
              Close Application
            </Button>
          </Modal>
        }
        {deleteApplicationOptions &&
          <Modal
            open={deleteApplicationOptions !== null}
            close={(): void => setDeleteApplicationOptions(null)}
            title="Delete Application"
          >
            <p className="italic">Do you want to remove permanently this application?</p>
            <div className="flex justify-end mt-4">
              <Button theme="secondary" onClick={(): void => setDeleteApplicationOptions(null)}>Cancel</Button>
              <Button
                onClick={async(): Promise<void> => {
                  setApplicationActionLoading(true)
                  await deleteApplication()
                  setApplications(applications => {
                    const updated = applications
                    const target = applications.find(({ application: { applicationId } }) => applicationId === deleteApplicationOptions.applicationId)
                    if (target) {
                      const index = applications.indexOf(target)
                      updated.splice(index, 1)
                    }
                    return updated as ApplicationDataType[]
                  })
                  setDeleteApplicationOptions(null)
                  setApplicationActionLoading(false)
                  changeSection("closed")
                }}
                className="ml-3"
              >
                {applicationActionLoading &&
                  <Spinner size={20} className="mr-3" color="white" />
                }
                Delete
              </Button>
            </div>
          </Modal>
        }
      </>
      :
      <Message title="No applications found"/>
  )
}

export default ApplicationsList
