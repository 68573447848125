import { createRef, FC } from "react"
import { sidebar } from "../../pages/wrappers/Private"
import HeaderUser from "./HeaderUser"

export const hamburger = createRef<SVGSVGElement>()

const Header: FC = () => {
  return (
    <header className="header">
      <div className="header__wrapper">
        <div className="header__content">

          <div className="header__left">
            <button
              className="header__toggle"
              aria-controls="sidebar"
              onClick={(): void => {
                sidebar.current?.toggle()
              }}
            >
              <span className="sr-only">Open sidebar</span>

              <svg className="header__toggle-icon" ref={hamburger} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <rect x="4" y="5" width="16" height="2" />
                <rect x="4" y="11" width="16" height="2" />
                <rect x="4" y="17" width="16" height="2" />
              </svg>
            </button>
          </div>

          <div className="header__right">
            <HeaderUser />
          </div>

        </div>
      </div>
    </header>
  )
}

export default Header
