import classNames from "classnames"
import { FC, ReactNode } from "react"
import Row from "./Row"
import { SetState } from "../types/ui"

export type TableRow = { id: string, children: ReactNode[], onRowClick?(): void, onRowHover?(): void, onRowOut?(): void }

type Props = {
  title?: string
  side?: ReactNode
  headings: string[]
  fixedColumns?: string[]
  rows: TableRow[]
  selectable?: boolean
  selectedItems?: Set<string>
  setSelectedItems?: SetState<Set<string>>
  wrapperClassName?: string
  tableClassName?: string
}

const Table: FC<Props> = ({
  title,
  headings,
  fixedColumns = [],
  rows,
  side = "",
  selectable = false,
  selectedItems,
  setSelectedItems,
  wrapperClassName,
  tableClassName
}) => {
  return <div className={classNames("custom-table__wrapper", wrapperClassName)}>
    {title &&
      <Row as={"header"} justify={"between"}>
        <h2>{title}</h2>
        {side}
      </Row>
    }
    <div className={classNames("custom-table", tableClassName)}>
      <table>
        <thead>
          <tr>
            {
              selectable && !!selectedItems && !!setSelectedItems && <th className={"table__column w-px"}>
                <Row align={"center"}>
                  <label className={"inline-flex"}>
                    <span className={"sr-only"}>Select all</span>
                    <input
                      className={"form-checkbox"}
                      type={"checkbox"}
                      disabled={!rows.length}
                      checked={selectedItems.size === rows.length && !!rows.length}
                      onChange={(event): void => {
                        if (event.target.checked) {
                          setSelectedItems(new Set(rows.map(({ id }) => id)))
                        } else {
                          setSelectedItems(new Set())
                        }
                      }}
                    />
                  </label>
                </Row>
              </th>
            }
            {
              headings.map((heading, i) => (
                <th key={`heading-${i}`} className={classNames({ "w-px": fixedColumns.includes(heading) })}>{heading}</th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          {
            rows.map(({ id: rowId, children, onRowClick, onRowHover, onRowOut }, i) =>
              <tr
                key={`row-${i}`}
                className={classNames((onRowClick || onRowHover) && "row__clickable")}
                onMouseOver={onRowHover}
                onMouseOut={onRowOut}
                onClick={onRowClick}
              >
                {
                  selectable && !!selectedItems && !!setSelectedItems && <td>
                    <Row align={"center"}>
                      <label className={"inline-flex"}>
                        <span className={"sr-only"}></span>
                        <input
                          id={rowId}
                          onChange={(event): void => {
                            setSelectedItems(selectedItems => {
                              if (event.target.checked) {
                                selectedItems.add(rowId)
                              } else {
                                selectedItems.delete(rowId)
                              }

                              return new Set(selectedItems)
                            })
                          }}
                          checked={selectedItems.has(rowId)}
                          className={"form-checkbox"}
                          type={"checkbox"}
                        />
                      </label>
                    </Row>
                  </td>
                }
                {
                  children.map((field, j) => (
                    <td key={`field-${j}`}>{field}</td>
                  ))
                }
              </tr>)
          }
        </tbody>
      </table>
    </div>
  </div>
}


export default Table